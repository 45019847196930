var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "_flexbtwn _mb-2 _ai-b" }, [
        _c(
          "div",
          { staticClass: "_tc--gray gfu-bt--caption _ttu" },
          [
            _vm._v("\n            " + _vm._s(_vm.title) + "\n            "),
            _vm.tooltipText
              ? _c(
                  "tooltip",
                  { attrs: { text: _vm.tooltipText } },
                  [_c("base-icon", { attrs: { name: "info" } })],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "_tc--dark gfu-bt--b1 _whs-nw _ml-3",
            attrs: { "data-qa": "statistic-item-value" },
          },
          [_c("b", [_vm._v(_vm._s(_vm.value))])]
        ),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-box",
    {
      attrs: {
        "is-visible": _vm.isOpen,
        "is-processing": _vm.isProcessing,
        icon: "user-circle",
        title: _vm.Resources.AccountSettings.AvatarManagerModalHeader,
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "a",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: { href: "#", "data-qa": "modal-button:Cancel" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onClose.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalCancelButton))]
              ),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  attrs: {
                    size: "default",
                    width: "compact",
                    "text-transform": "none",
                    bold: true,
                    theme: "accent",
                    disabled: _vm.isProcessing || !_vm.canSave,
                    "data-qa": "modal-button:Save",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onSave.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Common.ModalSaveButton))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "_flex _gap-1" },
        _vm._l(_vm.avatarModeTypes, function (type) {
          return _c(
            "base-tab",
            {
              key: type.key,
              attrs: {
                title: type.label,
                variant: "link",
                tag: "a",
                "is-active": type.isActive,
                "data-qa": type.dataQa,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.changeAvatarMode(type.key)
                },
              },
            },
            [_vm._v("\n            " + _vm._s(type.label) + "\n        ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_flex _jc-c _ai-c" },
        [
          _c(
            _vm.activeComponent.component,
            _vm._b(
              {
                tag: "component",
                on: {
                  "image-changed": _vm.onImageChanged,
                  "avatar-changed": _vm.onAvatarChanged,
                },
              },
              "component",
              _vm.activeComponent.props,
              false
            )
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { class: ["gfu-sticky-table", _vm.columnsGridClassName] },
    [
      _vm.$slots["head"]
        ? _c("thead", { staticClass: "gfu-sticky-table__header" }, [
            _c("tr", { staticClass: "_contents" }, [_vm._t("head")], 2),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots["body"]
        ? _c(
            "tbody",
            { staticClass: "gfu-sticky-table__body" },
            [_vm._t("body")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("terms-of-service-checkbox-field", {
        attrs: { name: "model.termsAccepted" },
        model: {
          value: _vm.model.termsAccepted,
          callback: function ($$v) {
            _vm.$set(_vm.model, "termsAccepted", $$v)
          },
          expression: "model.termsAccepted",
        },
      }),
      _vm._v(" "),
      _vm.requireAcceptDigitalDelivery
        ? _c(
            "checkbox-field",
            {
              attrs: {
                condensed: true,
                "display-name": "Digital delivery agreement",
                name: "model.digitalDeliveryAccepted",
                "data-qa": "digital-delivery-agreement",
              },
              model: {
                value: _vm.digitalDeliveryAccepted,
                callback: function ($$v) {
                  _vm.digitalDeliveryAccepted = $$v
                },
                expression: "digitalDeliveryAccepted",
              },
            },
            [
              _c("em", [_vm._v("*")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.Resources.Checkout
                      .CheckboxDigitalDeliveryAgreementMessage
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-box-actions" }, [
        _c(
          "div",
          { staticClass: "gfu-box-actions__item" },
          [
            _vm.isBackButtonVisible
              ? _c(
                  "base-link",
                  {
                    staticClass: "gfu-link gfu-link--accent",
                    attrs: { url: _vm.backAction },
                    on: {
                      click: function ($event) {
                        return _vm.onBackButtonClick($event, _vm.backAction)
                      },
                    },
                  },
                  [
                    _c("base-icon", {
                      staticClass: "_fl _lhb",
                      attrs: { name: "chevron-left" },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "_ml-1",
                        attrs: { "data-qa": "create-order-btn:Back" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.Resources.Checkout.CheckoutGoBackLink)
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "gfu-box-actions__item _ml-a",
            attrs: { action: _vm.formAction, method: "post" },
            on: { submit: _vm.onSubmit },
          },
          [
            _c("input", {
              attrs: { type: "hidden", name: "orderCode" },
              domProps: { value: _vm.orderCode },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "hidden", name: _vm.antiForgeryTokenKey },
              domProps: { value: _vm.antiForgeryToken },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.paymentToken,
                  expression: "paymentToken",
                },
              ],
              attrs: { type: "hidden", name: "paymentToken" },
              domProps: { value: _vm.paymentToken },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.paymentToken = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.digitalDeliveryAccepted,
                  expression: "digitalDeliveryAccepted",
                },
              ],
              attrs: { type: "hidden", name: "digitalDeliveryAccepted" },
              domProps: { value: _vm.digitalDeliveryAccepted },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.digitalDeliveryAccepted = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.paymentMethod,
                  expression: "paymentMethod",
                },
              ],
              attrs: { type: "hidden", name: "paymentMethod" },
              domProps: { value: _vm.paymentMethod },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.paymentMethod = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "tooltip",
              { attrs: { enabled: !_vm.isValid, text: _vm.validationMessage } },
              [
                _c(
                  "base-button",
                  {
                    staticClass: "gfu-btn--block",
                    attrs: {
                      theme: "accent",
                      type: "submit",
                      enabled: _vm.isValid,
                      "data-qa": "create-order-btn:Submit",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.Resources.Checkout.CheckoutSubmitOrderButton
                        ) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
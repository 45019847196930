var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-nav gfu-nav--secondary" }, [
    _c(
      "div",
      {
        staticClass:
          "gfu-nav__content gfu-nav__content _jc-c gfu-nav__content--large-screen",
      },
      _vm._l(_vm.navigationTabs, function ({ key, href, text, classes }) {
        return _c(
          "div",
          { key: key, class: classes },
          [
            _c("base-link", { staticClass: "gfu-link", attrs: { url: href } }, [
              _vm._v(_vm._s(text)),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
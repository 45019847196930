var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "_pos-r",
      attrs: { "data-qa": `project-comment-ID:${_vm.commentID}` },
    },
    [
      _c(
        "div",
        { staticClass: "gfu-bt gfu-bt--caption" },
        [
          _c("span", { staticClass: "_tc--light" }, [
            _vm._v(_vm._s(_vm.Resources.Comments.CommentPreviewItemInLabel)),
          ]),
          _vm._v(" "),
          _c(
            "base-link",
            {
              staticClass: "_tc--accent",
              attrs: {
                url: _vm.contextUrl,
                "data-qa": "profile-comment:RedirectLink",
              },
            },
            [_vm._v(_vm._s(_vm.contextName))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-media _mt-2" }, [
        _c(
          "div",
          { staticClass: "_pos-r" },
          [
            _c(
              "base-avatar",
              {
                attrs: {
                  "avatar-url": _vm.avatarUrl,
                  alt: _vm.nickname,
                  clean: true,
                },
              },
              [
                _c("user-follow", {
                  attrs: {
                    nickname: _vm.nickname,
                    "user-url-name": _vm.userUrlName,
                    "user-id": _vm.userId,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ isFollowing }) {
                        return [
                          isFollowing
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "gfu-avatar--followed _flex _jc-c _ai-c _bgc--accent _tc--white",
                                },
                                [
                                  _c("base-icon", {
                                    attrs: {
                                      "icon-namespace": "ga",
                                      name: "heart-solid-icon",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "_ml-2" }, [
          _c("div", { staticClass: "_mb-1" }, [
            _c(
              "span",
              {
                staticClass: "gfu-hd gfu-hd--h4",
                attrs: { "data-qa": "profile-comment:Nickname" },
              },
              [_vm._v(_vm._s(_vm.nickname))]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "gfu-bt gfu-bt--caption _tc--light _ml-1",
                attrs: { "data-qa": "profile-comment:Time" },
              },
              [_vm._v(_vm._s(_vm.timeDifferenceText))]
            ),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { "data-qa": "profile-comment:Text" } }, [
            _vm._v(_vm._s(_vm.text)),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
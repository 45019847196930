var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasSlides
    ? _c(
        "touch",
        {
          attrs: { enabled: _vm.canSwipe },
          on: { "swipe-left": _vm.next, "swipe-right": _vm.prev },
        },
        [
          _c(
            "div",
            {
              on: {
                mouseenter: _vm.onMouseEnter,
                mouseleave: _vm.onMouseLeave,
              },
            },
            [
              _vm._t("default", null, null, {
                currentItem: _vm.currentItem,
                onAction: _vm.onAction,
                stopAutoplay: _vm.stopAutoplay,
                startAutoplay: _vm.startAutoplay,
              }),
              _vm._v(" "),
              _vm._t("view", null, null, {
                currentItem: _vm.currentItem,
                onAction: _vm.onAction,
              }),
              _vm._v(" "),
              _vm.hasMoreThanOneSlide
                ? _vm._t(
                    "nav",
                    function () {
                      return [
                        !_vm.hasControlsSlots
                          ? [
                              _c("base-slider-navigation", {
                                attrs: { type: "prev", icon: "angle-left" },
                                on: { click: _vm.onPrev },
                              }),
                              _vm._v(" "),
                              _c("base-slider-navigation", {
                                attrs: { type: "next", icon: "angle-right" },
                                on: { click: _vm.onNext },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    },
                    null,
                    {
                      onNext: _vm.onNext,
                      onPrev: _vm.onPrev,
                      onAction: _vm.onAction,
                    }
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("controls", null, null, {
                onNext: _vm.onNext,
                onPrev: _vm.onPrev,
              }),
              _vm._v(" "),
              _vm.hasMoreThanOneSlide
                ? _vm._t("indicators", null, null, {
                    onSetCurrentIndex: _vm.onSetCurrentIndex,
                    currentItem: _vm.currentItem,
                    indicators: _vm.indicators,
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("actions", null, null, { onAction: _vm.onAction }),
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
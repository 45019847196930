var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "base-link",
        {
          class: [
            "gfu-promo-banner",
            { "gfu-promo-banner--closed": _vm.closed },
            _vm.visibilityClass,
          ],
          style: _vm.backgroundColorStyle(_vm.variantLarge.backgroundStyle),
          attrs: {
            url: _vm.projectUrl,
            title: _vm.projectName,
            "data-qa": `advert-banner:${_vm.projectAdvertID}`,
          },
          on: { click: _vm.onClick },
        },
        [
          _c("div", { staticClass: "gfu-promo-banner__holder" }, [
            _c("span", {
              staticClass:
                "gfu-promo-banner-background gfu-promo-banner-display--small",
              style: _vm.backgroundImageStyle(
                _vm.variantSmall.backgroundImageUrl
              ),
            }),
            _vm._v(" "),
            _c("span", {
              staticClass:
                "gfu-promo-banner-background gfu-promo-banner-display--medium",
              style: _vm.backgroundImageStyle(
                _vm.variantMedium.backgroundImageUrl
              ),
            }),
            _vm._v(" "),
            _c("span", {
              staticClass:
                "gfu-promo-banner-background gfu-promo-banner-display--large",
              style: _vm.backgroundImageStyle(
                _vm.variantLarge.backgroundImageUrl
              ),
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gfu-promo-banner__layout" },
            [
              _c(
                "base-link",
                {
                  staticClass: "gfu-promo-banner__head",
                  attrs: { url: _vm.projectUrl },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-promo-banner-underlay gfu-promo-banner-display--small",
                      style: _vm.backgroundImageStyle(
                        _vm.variantSmall.underlayImageUrl
                      ),
                    },
                    [
                      _c("div", {
                        staticClass:
                          "gfu-promo-banner-packshot gfu-promo-banner-display--small",
                        style: _vm.backgroundImageStyle(
                          _vm.variantSmall.packshotImageUrl
                        ),
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-promo-banner-underlay gfu-promo-banner-display--medium",
                      style: _vm.backgroundImageStyle(
                        _vm.variantMedium.underlayImageUrl
                      ),
                    },
                    [
                      _c("div", {
                        staticClass:
                          "gfu-promo-banner-packshot gfu-promo-banner-display--medium",
                        style: _vm.backgroundImageStyle(
                          _vm.variantMedium.packshotImageUrl
                        ),
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-promo-banner-underlay gfu-promo-banner-display--large",
                      style: _vm.backgroundImageStyle(
                        _vm.variantLarge.underlayImageUrl
                      ),
                    },
                    [
                      _c("div", {
                        staticClass:
                          "gfu-promo-banner-packshot gfu-promo-banner-display--large",
                        style: _vm.backgroundImageStyle(
                          _vm.variantLarge.packshotImageUrl
                        ),
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gfu-promo-banner__content" }, [
                _c(
                  "div",
                  { staticClass: "gfu-promo-banner__text-container" },
                  [
                    _c("div", { staticClass: "_flexcol _ai-c" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "gfu-promo-banner__title gfu-promo-banner-display--small text-stroke",
                          style: {
                            "-webkit-text-stroke-color":
                              _vm.variantSmall.titleStyle,
                          },
                          attrs: { "data-title": _vm.variantSmall.title },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.variantSmall.title) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "gfu-promo-banner__title gfu-promo-banner-display--medium text-stroke",
                          style: {
                            "-webkit-text-stroke-color":
                              _vm.variantMedium.titleStyle,
                          },
                          attrs: { "data-title": _vm.variantMedium.title },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.variantMedium.title) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "gfu-promo-banner__title gfu-promo-banner-display--large text-stroke",
                          style: {
                            "-webkit-text-stroke-color":
                              _vm.variantLarge.titleStyle,
                          },
                          attrs: {
                            "data-title": _vm.variantLarge.title,
                            "data-qa": "advert-banner:TitleLarge",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.variantLarge.title) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          class: [
                            "gfu-promo-banner__subtitle gfu-promo-banner-display--medium",
                            { "text-stroke": _vm.subtitleLabelMedium },
                          ],
                          style: {
                            "-webkit-text-stroke-color":
                              _vm.variantMedium.titleStyle,
                          },
                          attrs: { "data-title": _vm.subtitleLabelMedium },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.subtitleLabelMedium) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          class: [
                            "gfu-promo-banner__subtitle gfu-promo-banner-display--large",
                            { "text-stroke": _vm.subtitleLabelLarge },
                          ],
                          style: {
                            "-webkit-text-stroke-color":
                              _vm.variantLarge.titleStyle,
                          },
                          attrs: {
                            "data-title": _vm.subtitleLabelLarge,
                            "data-qa": "advert-banner:SubTitleLarge",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.subtitleLabelLarge) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.showCountDown
                      ? _c("countdown", {
                          staticClass: "gfu-promo-banner__countdown",
                          attrs: {
                            "pad-numbers": true,
                            timestamp: _vm.countdownDate,
                            "data-qa": "advert-banner:EndDate",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "gfu-promo-banner__actions _tac" }, [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "gfu-btn gfu-btn--soft gfu-btn--block _px-5 gfu-btn--wide _whs-nw gfu-promo-banner__btn",
                        style: _vm.buttonStyle(_vm.variantSmall.titleStyle),
                        attrs: { "data-qa": "advert-banner:ActionLabel" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "gfu-promo-banner-display--small" },
                          [_vm._v(_vm._s(_vm.variantSmall.actionLabel))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "gfu-promo-banner-display--medium" },
                          [_vm._v(_vm._s(_vm.variantMedium.actionLabel))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "gfu-promo-banner-display--large" },
                          [_vm._v(_vm._s(_vm.variantLarge.actionLabel))]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "gfu-promo-banner__link-close" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "gfu-link gfu-promo-banner__link text-stroke",
                        style: {
                          "-webkit-text-stroke-color":
                            _vm.variantSmall.titleStyle,
                        },
                        attrs: {
                          "data-title":
                            _vm.Resources.Homepage.HideAdvertBannerButton,
                          "data-qa": "advert-banner:Hide",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onClose.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.Resources.Homepage.HideAdvertBannerButton)
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("footer-navigation", {
        attrs: { "current-page": _vm.Resources.Layout.MarketingServicesLink },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-marketing-services-hero" },
        [
          _c(
            "h2",
            {
              staticClass:
                "gfu-hd gfu-hd--d2 gfu-hd--decorative _tc--accent-inverse _mb-4",
            },
            [_vm._v("\n            Gamefound marketing services\n        ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-become-creator-hero__content _mb-6" }, [
            _vm._v(
              "\n            Discover new audiences and boost your campaign's results with the support of our experienced marketing team.\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "base-link",
            {
              staticClass:
                "gfu-btn gfu-btn--primary gfu-btn--soft gfu-btn--wide _tac",
              attrs: { url: _vm.contactUrl },
            },
            [_vm._v("\n            Boost your campaign\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "gfu-layout-wrapper gfu-layout-wrapper--narrow _pb-6" },
        [
          _c("marketing-services-benefits", {
            attrs: {
              "marketing-services-benefits": _vm.marketingServicesBenefits,
            },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "gfu-hd gfu-hd--h1" }, [
            _vm._v("Marketing services"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-bt gfu-bt--b1 _mb-6" }, [
            _vm._v("Bring your project the attention it deserves."),
          ]),
          _vm._v(" "),
          _c("marketing-services-values", {
            attrs: { "marketing-services-values": _vm.marketingServicesValues },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "gfu-hd gfu-hd--h1 _my-6" }, [
            _vm._v("They trusted us"),
          ]),
          _vm._v(" "),
          _c("testimonials-slider", {
            attrs: {
              testimonials: _vm.testimonials,
              page: "MarketingServices",
            },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "gfu-hd gfu-hd--h1" }, [_vm._v("Our offer")]),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-bt gfu-bt--b1 _mb-6" }, [
            _vm._v(
              "Whether you choose Gamefound or other services for your crowdfunding, we have an offer for you."
            ),
          ]),
          _vm._v(" "),
          _c("check-list-table", { attrs: { "table-data": _vm.tableData } }),
          _vm._v(" "),
          _c("h2", { staticClass: "gfu-hd gfu-hd--h1 _mt-6" }, [
            _vm._v("How we do it"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-bt gfu-bt--b1 _mb-6" }, [
            _vm._v(
              "We take advantage of our experience and resources to do what's best for your campaign."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "_my-6" }, [
            _c(
              "div",
              {
                staticClass:
                  "gfu-box gfu-box--flat gfu-box--huge _my-6 gfu-bt gfu-bt--b1",
              },
              _vm._l(_vm.mediaData, function (media) {
                return _c(
                  "media-description",
                  _vm._b(
                    { key: media.title },
                    "media-description",
                    media,
                    false
                  )
                )
              }),
              1
            ),
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "gfu-hd gfu-hd--h1 _mb-6" }, [
            _vm._v("Reach out"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "gfu-box gfu-box--flat gfu-box--huge _my-6 gfu-bt gfu-bt--b1 _tac",
            },
            [
              _c("div", {
                staticClass: "_fa _tc--accent fa-4x _fa--envelope _mb-6",
              }),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "Contact us for a custom marketing plan for your project."
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "_mt-6" },
                [
                  _c(
                    "base-link",
                    {
                      staticClass:
                        "gfu-btn gfu-btn--primary gfu-btn--soft gfu-btn--wide _tac",
                      attrs: { url: _vm.contactUrl },
                    },
                    [
                      _vm._v(
                        "\n                    Get personalized offer\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
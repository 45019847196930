var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.areRecommendationsVisible
    ? _c(
        "div",
        { staticClass: "gfu-recommendations" },
        [
          _c(
            "processing-wrapper",
            { attrs: { active: _vm.isProcessing } },
            [
              _c(
                "intersection-trigger",
                {
                  attrs: { "root-margin": _vm.intersectionRootMarginPx },
                  on: { intersected: _vm.onViewportIntersect },
                },
                [
                  _c(
                    "slider-container",
                    {
                      staticClass: "gfu-recommendations__slider",
                      attrs: {
                        centered: false,
                        "arrow-style": "circle",
                        "slides-to-scroll": _vm.slidesToShow,
                        "background-style": "transparent",
                        "slides-wrapper-class":
                          "gfu-recommendations__slides-wrapper",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c(
                                  "h2",
                                  { staticClass: "gfu-hd gfu-hd--h2 _mb-1" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .ProjectRecommendationsTitle
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "localize",
                                  {
                                    attrs: {
                                      resource:
                                        _vm.Resources.ProjectHome
                                          .ProjectRecommendationsText,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { attrs: { "format-key": "0" } },
                                      [
                                        _c(
                                          "base-link",
                                          {
                                            staticClass:
                                              "gfu-link gfu-link--accent",
                                            attrs: { url: "/projects/search" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.Resources.ProjectHome
                                                  .ProjectRecommendationsSearchLink
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1802226199
                      ),
                    },
                    [
                      _vm._v(" "),
                      _vm._l(_vm.recommendations, function (recommendation) {
                        return _c(
                          "div",
                          {
                            key: recommendation.project.projectID,
                            class: [
                              "gfu-card-list__item gfu-5of6 gfu-2of5--m _shrink-0 _grow-0 _flex",
                              _vm.slideLargeScreenClasses,
                            ],
                            attrs: {
                              "data-qa": `project-card-ID:${recommendation.project.projectID}`,
                            },
                          },
                          [
                            _c(
                              "project-card",
                              _vm._b(
                                { staticClass: "_grow-1" },
                                "project-card",
                                recommendation.project,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      !_vm.isInitialized
                        ? _vm._l(_vm.placeholders, function (placeholder) {
                            return _c(
                              "div",
                              {
                                key: placeholder,
                                class: [
                                  "gfu-card-list__item gfu-5of6 gfu-2of5--m _shrink-0 _grow-0 _flex",
                                  _vm.slideLargeScreenClasses,
                                ],
                              },
                              [_c("skeleton-card", { staticClass: "_grow-1" })],
                              1
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
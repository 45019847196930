var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b({}, "field", { ..._vm.$attrs, ..._vm.$props }, false),
    [
      _c(
        "div",
        { staticClass: "_wbbw", class: { _cf: _vm.isCondensedLayout } },
        [
          !_vm.isEditing && _vm.isUrlClickable
            ? _c(
                "base-link",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    url: _vm.formattedBaseUrl + _vm.model,
                    target: "_blank",
                    rel: "noopener noreferrer",
                    "data-qa": "url-field:UrlLink",
                  },
                },
                [
                  _vm._v("\n            " + _vm._s(_vm.formattedBaseUrl)),
                  _c("span", { attrs: { "data-qa": "url-field:UrlSegment" } }, [
                    _vm._v(_vm._s(_vm.model || "...")),
                  ]),
                ]
              )
            : _c(
                "span",
                {
                  class: { "_fl _mt-2": _vm.isCondensedLayout },
                  attrs: { "data-qa": "url-field:UrlText" },
                },
                [
                  _vm._v(_vm._s(_vm.formattedBaseUrl)),
                  !_vm.isEditing
                    ? _c(
                        "span",
                        { attrs: { "data-qa": "url-field:UrlSegment" } },
                        [_vm._v(_vm._s(_vm.model || "..."))]
                      )
                    : _vm._e(),
                ]
              ),
          _vm._v(" "),
          _vm.isEditing
            ? _c(
                "div",
                {
                  staticClass: "gfu-flex__container",
                  class: { "_fl _ml-1": _vm.isCondensedLayout },
                },
                [
                  _c(
                    "div",
                    { staticClass: "_flexb90" },
                    [
                      _c("text-field", {
                        ref: "customUrlNameInput",
                        staticClass: "_mt-0",
                        attrs: {
                          "show-label": false,
                          "data-qa": "url-field:UrlInput",
                        },
                        on: {
                          keyup: [
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.confirmUrlChange.apply(null, arguments)
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "esc", 27, $event.key, [
                                  "Esc",
                                  "Escape",
                                ])
                              )
                                return null
                              return _vm.cancelUrlEditing.apply(null, arguments)
                            },
                          ],
                        },
                        model: {
                          value: _vm.model,
                          callback: function ($$v) {
                            _vm.model = $$v
                          },
                          expression: "model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "gfu-url-field__actions _pt-1" }, [
                    _c("a", {
                      class: [
                        "gfu-btn--toggler _fa _fa--check gfu-btn gfu-btn--circle gfu-btn--accent",
                        { "gfu-btn--disabled": _vm.urlHasError },
                      ],
                      attrs: {
                        disabled: _vm.urlHasError,
                        "data-qa": "url-field:SaveUrl",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.confirmUrlChange.apply(null, arguments)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("a", {
                      staticClass:
                        "gfu-btn--toggler _fa _fa--times gfu-btn gfu-btn--circle gfu-btn--light-alt",
                      attrs: { "data-qa": "url-field:CancelUrl" },
                      on: { click: _vm.cancelUrlEditing },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isEditing
            ? [
                _c(
                  "div",
                  { staticClass: "_pt-1" },
                  [_vm._t("edit", null, { onEditClick: _vm.startUrlEditing })],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
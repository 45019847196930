var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      class: _vm.formClasses,
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "teleport",
    { attrs: { to: "#modals" } },
    [
      _c("transition", _vm._g({}, _vm.$listeners), [
        _vm.isVisible
          ? _c(
              "div",
              {
                staticClass: "gfu-modal gfu-modal--visible",
                attrs: {
                  "data-qa": `modal-visible-${_vm.isVisible}:${_vm.effectiveDataQa}`,
                },
              },
              [
                _vm.hasBackdrop
                  ? _c("div", {
                      staticClass: "gfu-modal__backdrop",
                      on: { click: _vm.onActionClose },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  _vm.tag,
                  _vm._g(
                    _vm._b(
                      {
                        tag: "component",
                        class: ["gfu-modal__body", _vm.modalBodyClassName],
                        attrs: { tabindex: "0", "data-qa": "modal-box:Body" },
                      },
                      "component",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  ),
                  [
                    _vm._t("navigation", function () {
                      return [
                        _c("div", { staticClass: "_flex _jc-sb" }, [
                          _vm.title || _vm.subtitle
                            ? _c(
                                "div",
                                {
                                  staticClass: "gfu-modal__header",
                                  attrs: { "data-qa": "box:Title" },
                                },
                                [
                                  _vm.title
                                    ? _c(
                                        "h1",
                                        { staticClass: "gfu-heading _flex" },
                                        [
                                          _vm.icon !== null
                                            ? _c("span", {
                                                staticClass:
                                                  "gfu-modal__header-icon _tc--accent",
                                                class: "_fa _fa--" + _vm.icon,
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "gfu-modal__header-text",
                                            },
                                            [_vm._v(_vm._s(_vm.title))]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.subtitle
                                    ? _c(
                                        "div",
                                        { staticClass: "gfu-subheading" },
                                        [_vm._v(_vm._s(_vm.subtitle))]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showActionBack || _vm.showActionClose
                            ? _c(
                                "div",
                                { staticClass: "gfu-modal-navigation" },
                                [
                                  _vm.showActionBack
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "_flexaicntr _tc--accent",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.onActionBack.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "gfu-modal-navigation__icon _mr-1 _fa _fa--angle-left _fl",
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.Resources.Common
                                                  .ModalBackButton
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.showActionClose
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "_flexaicntr _tc--accent _ml-a",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.onActionClose.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.Resources.Common
                                                  .ModalCloseButton
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass:
                                              "gfu-modal-navigation__icon _fa _fa--times _ml-1 _fr",
                                            attrs: {
                                              "data-qa": "modal-button:Close",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "gfu-modal__content" },
                      [_vm._t("default")],
                      2
                    ),
                    _vm._v(" "),
                    _vm.$slots["actions"]
                      ? _c(
                          "div",
                          { staticClass: "gfu-modal__footer gfu-form__footer" },
                          [_vm._t("actions")],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("progress-overlay", {
                      staticClass: "gfu-progress-overlay--modal",
                      attrs: {
                        visible: _vm.isProcessing,
                        message: _vm.processingMessage,
                      },
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
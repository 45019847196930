import { projectRankingsType, projectRankingsTimeSpanTypes } from '@/Models';
import Formatter from '@/Utils/Formatter';
import Resources from '@/Resources';
import { addRef } from '@/Utils/UrlUtility';
import { formatStringToRefValue } from '@/Utils/StringUtility';

export function buildProjectRankingsModel(projects, type, timespan, category = null, categories = null ) {

    const rankingTypeNames = {
        [projectRankingsType.funding]: Resources.Homepage.RankingsFundingTypeLabel,
        [projectRankingsType.audience]: Resources.Homepage.RankingsAudienceTypeLabel,
    };

    return projects.map((rankingItem, index) => ({
        name: rankingItem.project.name,
        description: Resources.format(Resources.Homepage.ProjectCardByCreatorLabel, rankingItem.project.creatorName),
        imageUrl: rankingItem.project.imageUrl,
        url: addRef('homepage', 'rankings', rankingItem.project.url, buildRankingFiltersKey(type, timespan, category, categories), index + 1),
        valueTitle: rankingTypeNames[type],
        value: buildRankingValue(rankingItem.value, timespan, type),
    }));
}

function buildRankingValue(value, timespan, type) {
    const prefix = timespan !== projectRankingsTimeSpanTypes.allTime && value > 0
        ? '+'
        : '';

    return type === projectRankingsType.funding
        ? `${prefix}${Formatter.format(value, 'C0', '€')}`
        : `${prefix}${Formatter.format(value, 'N0')}`;
}

export function buildRankingFiltersKey(type, timespan, category, categories) {
    let categoryRefKey = null;
    if (categories) {
        categoryRefKey = categories.reduce((acc, category) => {
            acc[category.projectCategoryID] = formatStringToRefValue(category.name);
            return acc;
        }, {  null: 'all-categories' })[category];
    }

    const timespanRefKeys = {
        [projectRankingsTimeSpanTypes.allTime]: 'all-time',
        [projectRankingsTimeSpanTypes.last30Days]: 'last-30-days',
        [projectRankingsTimeSpanTypes.last7Days]: 'last-7-days',
        [projectRankingsTimeSpanTypes.last24Hours]: 'last-24-hours',
    }[timespan];

    const rankingTypeRefKeys = {
        [projectRankingsType.funding]: 'funding',
        [projectRankingsType.audience]: 'audience',
    }[type];

    return categories
        ? `${rankingTypeRefKeys}-${timespanRefKeys}-${categoryRefKey}`
        : `${rankingTypeRefKeys}-${timespanRefKeys}`;
}
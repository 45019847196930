var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      class: [
        "gfu-navbar-item gfu-link-underline",
        { "is-active": _vm.link.isActive },
      ],
      attrs: { href: _vm.href, url: _vm.href },
    },
    [
      _vm.link.isRestricted && _vm.link.canBypassRestriction
        ? [
            _c(
              "tooltip",
              {
                staticClass: "gfu-navbar-tooltip",
                attrs: {
                  tag: "div",
                  text: _vm.link.restrictionReason,
                  theme: "light",
                  "use-native-click": true,
                  "vertical-position": "down",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "gfu-navbar-link gfu-navbar-link--disabled",
                    attrs: {
                      "data-qa": `project-navigation-link:${_vm.link.name}`,
                    },
                  },
                  [
                    _c("span", { staticClass: "gfu-link-underline__content" }, [
                      _vm._v(_vm._s(_vm.link.name)),
                    ]),
                    _vm._v(" "),
                    _vm.link.badgeText
                      ? _c(
                          "span",
                          {
                            staticClass: "gfu-navbar-badge _ml-1",
                            attrs: { "data-qa": "project-navigation-badge" },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.link.badgeText) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        : _vm.link.isRestricted && !_vm.link.canBypassRestriction
        ? [
            _c(
              "tooltip",
              {
                attrs: {
                  text: _vm.link.restrictionReason,
                  theme: "light",
                  "vertical-position": "down",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "gfu-navbar-link gfu-navbar-link--disabled _pen",
                    attrs: {
                      "data-qa": `project-navigation-link:${_vm.link.name}`,
                    },
                  },
                  [
                    _c("span", { staticClass: "gfu-link-underline__content" }, [
                      _vm._v(_vm._s(_vm.link.name)),
                    ]),
                    _vm._v(" "),
                    _vm.link.badgeText
                      ? _c(
                          "span",
                          {
                            staticClass: "gfu-navbar-badge _ml-1",
                            attrs: { "data-qa": "project-navigation-badge" },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.link.badgeText) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        : [
            _c(
              "div",
              {
                staticClass: "gfu-navbar-link",
                attrs: {
                  "data-qa": `project-navigation-link:${_vm.link.name}`,
                },
              },
              [
                _c("span", { staticClass: "gfu-link-underline__content" }, [
                  _vm._v(_vm._s(_vm.link.name)),
                ]),
                _vm._v(" "),
                _vm.link.badgeText
                  ? _c(
                      "span",
                      {
                        staticClass: "gfu-navbar-badge _ml-1",
                        attrs: { "data-qa": "project-navigation-badge" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.link.badgeText) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-product-set-items _cf" },
    _vm._l(_vm.setItems, function (setItem) {
      return _c(
        "div",
        {
          key: `set-item-${setItem.productID}`,
          staticClass: "gfu-product-set-items__item gfu-1of2--m gfu-1of1",
        },
        [
          _c("div", { staticClass: "gfu-product-set-item gfu-media" }, [
            _c(
              "div",
              { staticClass: "gfu-thumb" },
              [
                _vm.isModal
                  ? _c(
                      "router-link",
                      { attrs: { to: _vm.route(setItem.productID) } },
                      [
                        _c("base-image", {
                          attrs: {
                            src: setItem.productImageUrl,
                            alt: setItem.name,
                            width: "48",
                            height: "48",
                            loading: "lazy",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "base-link",
                      { attrs: { url: setItem.productDetailsUrl } },
                      [
                        _c("base-image", {
                          attrs: {
                            src: setItem.productImageUrl,
                            alt: setItem.name,
                            width: "48",
                            height: "48",
                            loading: "lazy",
                          },
                        }),
                      ],
                      1
                    ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "gfu-media__body",
                attrs: { "data-qa": `reward-item-id:${setItem.productID}` },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "gfu-product-set-item__name gfu-hd gfu-hd--h4 gfu-hd--decorative _pl-2",
                    attrs: { "data-qa": "reward-item:Name" },
                  },
                  [
                    _vm.isModal
                      ? _c(
                          "router-link",
                          { attrs: { to: _vm.route(setItem.productID) } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(setItem.name) +
                                "\n                    "
                            ),
                          ]
                        )
                      : _c(
                          "base-link",
                          { attrs: { url: setItem.productDetailsUrl } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(setItem.name) +
                                "\n                    "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "_tc--lighter gfu-bt--caption _pl-2",
                    attrs: { "data-qa": "reward-item:Quantity" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.format(
                          _vm.Resources.Product
                            .RewardCardProductSetItemQuantityLabel,
                          setItem.quantity
                        )
                      )
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
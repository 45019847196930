var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-dcc-wrapper", attrs: { "data-qa": "dcc-wrapper" } },
    [
      _c("h3", { staticClass: "gfu-hd gfu-hd--h3 _mt-5" }, [
        _vm._v(
          _vm._s(_vm.Resources.Checkout.CheckoutTransactionCurrencyHeader)
        ),
      ]),
      _vm._v(" "),
      _vm.isProjectCurrencyVisible
        ? _c(
            "dcc-option",
            {
              attrs: {
                "label-text":
                  _vm.Resources.Checkout.CheckoutProjectCurrencyLabel,
                "checkbox-group-name": "dynamicCurrencyConversion",
                "checked-value": _vm.dccType.projectCurrency,
              },
              on: {
                input: function ($event) {
                  return _vm.onOptionChange(_vm.dccType.projectCurrency)
                },
              },
              model: {
                value: _vm.selectedOption,
                callback: function ($$v) {
                  _vm.selectedOption = $$v
                },
                expression: "selectedOption",
              },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.projectCurrencyAmount) + "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isDynamicCurrencyVisible
        ? _c(
            "dcc-option",
            {
              attrs: {
                "label-text": _vm.labelText,
                "checkbox-group-name": "dynamicCurrencyConversion",
                "checked-value": _vm.dccType.systemSuggestion,
              },
              on: {
                input: function ($event) {
                  return _vm.onOptionChange(_vm.dccType.systemSuggestion)
                },
              },
              model: {
                value: _vm.selectedOption,
                callback: function ($$v) {
                  _vm.selectedOption = $$v
                },
                expression: "selectedOption",
              },
            },
            [
              _vm.isForceDynamicConversionCurrency
                ? [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.suggestedCurrencyAmount) +
                        "\n        "
                    ),
                  ]
                : _c("option-field", {
                    attrs: {
                      inline: true,
                      options: _vm.availableCurrenciesOptions,
                      "show-label": false,
                      small: true,
                      "data-qa": "suggested-currency-select",
                    },
                    on: { change: _vm.onDropdownOptionChange },
                    model: {
                      value: _vm.suggestedCurrency,
                      callback: function ($$v) {
                        _vm.suggestedCurrency = $$v
                      },
                      expression: "suggestedCurrency",
                    },
                  }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_mt-2" },
        [
          _vm.isDccSystemSuggestionSelected
            ? _c(
                "localize",
                {
                  key: _vm.currencyConversionInfoDetail,
                  attrs: {
                    resource:
                      _vm.Resources.Checkout.CheckoutDccMoreInfoParagraph,
                  },
                },
                [
                  _c("span", { attrs: { "format-key": "0" } }, [
                    _vm._v(
                      _vm._s(_vm.originalCurrencyOrderAmount) +
                        " " +
                        _vm._s(_vm.cartDetails.currencyShortName)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { attrs: { "format-key": "1" } }, [
                    _vm._v(_vm._s(_vm.dropdownSelectedCurrencyShortName)),
                  ]),
                  _vm._v(" "),
                  _c("span", { attrs: { "format-key": "2" } }, [
                    _vm._v(_vm._s(_vm.currencyConversionInfoDetail)),
                  ]),
                ]
              )
            : _c("span", [_vm._v(_vm._s(_vm.currencyConversionInfoDetail))]),
          _vm._v(" "),
          _c(
            "base-link",
            {
              staticClass: "gfu-link gfu-link--accent",
              attrs: {
                target: "_blank",
                url: _vm.articleUrl,
                rel: "noreferrer nofollow",
              },
            },
            [_vm._v(_vm._s(_vm.Resources.Common.MoreInfoHeader))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
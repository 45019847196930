export function convertSearchParamsToObject(urlSearchParams) {
    if (urlSearchParams instanceof URLSearchParams) {
        const params = {};

        for (const k of urlSearchParams.keys()) {
            params[k] = urlSearchParams.get(k);
        }

        return params;
    }
    else {
        throw new Error('Parameter should be type of URLSearchParams');
    }
}

export function addSearchParams(url, params) {
    const urlObj = new URL(url);

    for (const key in params) {
        urlObj.searchParams.append(key, params[key]);
    }

    return urlObj.toString();
}

export function addRef(page, section, url = '', suffix = null, index = null) {
    if (url === null) {
        throw new Error('Url should be string');
    }

    const [path, queryString] = url.split('?');
    const params = new URLSearchParams(queryString);

    const prefix = `${page}-${section}`;
    const formattedSuffix = suffix !== null ? `-${suffix}` : '';
    const refValue = index !== null ? `${prefix}${formattedSuffix}_${index}` : `${prefix}${suffix}`;

    params.set('ref', refValue);

    return `${path}?${params.toString()}`;
}

export function getRefFromUrl(url) {
    if (!url) {
        return null;
    }
    const queryStringIndex = url.indexOf('?');

    if (queryStringIndex !== -1) {
        const queryString = url.substring(queryStringIndex + 1);
        const params = new URLSearchParams(queryString);
        const ref = params.get('ref');
        return ref;
    }
    else {
        return null;
    }
}

export function isValidUrl(urlString) {
    try {
        return Boolean(new URL(urlString));
    }
    catch {
        return false;
    }
}
import { isInstalledPWA } from '@/Utils/Browser';
import { displayModeTypes } from '@/Utils/PwaUtility';
import { isIOSApp } from '@/Utils/PwaUtility';

const getDisplayModeValues = (displayModes, displayMode) => ({
    isPWA: displayMode === displayModes.pwa,
    isWeb: displayMode === displayModes.web,
    currentModeName: displayMode,
});

export const displayMode = isInstalledPWA() || isIOSApp() ? displayModeTypes.pwa : displayModeTypes.web;
export const displayModeValues = getDisplayModeValues(displayModeTypes, displayMode);

export default {
    install(app) {
        document.addEventListener('DOMContentLoaded', (e) => {
            e.target.body.setAttribute('data-display-mode', displayMode);
        });
        app.mixin({
            computed: {
                $displayMode() {
                    return {
                        ...displayModeValues,
                    };
                },
            },
        });
    },
};
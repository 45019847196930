var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAuthenticated
    ? _c(
        "div",
        { staticClass: "gfu-navbar-item gfu-navbar-dropdown" },
        [
          _c(
            "toggle-trigger",
            {
              class: [
                "gfu-navbar-btn gfu-navbar-dropdown-pointer",
                { "gfu-header-action-avatar": _vm.hasAvatar },
              ],
              attrs: {
                tag: "button",
                "active-class": "is-visible",
                tabindex: "0",
                "data-qa": "header-icon:Userbox",
              },
            },
            [
              _vm.isImpersonated
                ? _c("base-icon", {
                    staticClass: "_tc--accent",
                    attrs: { name: "id-card", size: "lg" },
                  })
                : _vm.hasAvatar
                ? _c("base-image", {
                    staticClass: "gfu-avatar",
                    attrs: {
                      alt: "Avatar",
                      src: _vm.userProfileAvatarUrl,
                      width: _vm.avatarSize,
                      height: _vm.avatarSize,
                      "data-qa": "header-actions:Avatar",
                    },
                  })
                : _c("base-icon", { attrs: { name: "user-cog", size: "lg" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "toggle-container",
            {
              staticClass: "gfu-navbar-dropdown__content gfu-bt gfu-bt--b2",
              attrs: { "active-class": "is-visible", tabindex: "0" },
            },
            [
              _c("base-balloon", {
                attrs: {
                  "is-pointer-visible": false,
                  position: "right",
                  "data-qa": "user-box",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _vm.isImpersonated
                            ? _c(
                                "div",
                                { staticClass: "gfu-balloon__top-alert" },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.Resources.Layout
                                          .UserBoxImpersonatingLabel
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "gfu-balloon__top-title",
                              attrs: { "data-qa": "user-box:FullName" },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.userContext.user.fullName) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { "data-qa": "user-box:Email" } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.userContext.user.email) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isImpersonated
                            ? _c(
                                "div",
                                { staticClass: "_tac _mt-1" },
                                [
                                  _c("revert-impersonation-button", {
                                    attrs: {
                                      "data-qa": "user-box-button:Revert",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _vm._l(_vm.links, function (link, index) {
                            return [
                              _c("div", { key: link.navigationItemId }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "_flex _py-1 _ai-c _gap-2 _fw-b _jc-fs gfu-link-underline",
                                    attrs: {
                                      rel: link.rel,
                                      href: link.to,
                                      target: link.target,
                                      "data-qa": `user-box-button:${link.key}`,
                                    },
                                    on: { click: link.action },
                                  },
                                  [
                                    _c("base-icon", {
                                      staticClass: "_tc--accent",
                                      attrs: {
                                        "icon-namespace": link.icon.namespace,
                                        name: link.icon.name,
                                        "is-fixed-width": true,
                                        size: "lg",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "gfu-link-underline__content",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(link.label) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    link.isTypeExternal
                                      ? _c("base-icon", {
                                          staticClass: "_tc--accent _ml-a",
                                          attrs: {
                                            name: "external-link",
                                            "is-fixed-width": true,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                index === 0
                                  ? _c("div", {
                                      staticClass: "_bb-2 _bc--light _my-2",
                                    })
                                  : _vm._e(),
                              ]),
                            ]
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3952179975
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "_flex" },
        [
          _c(
            "base-link",
            {
              staticClass: "gfu-navbar-item _screen--none _screen--flex-m",
              attrs: { url: _vm.loginUrl, "data-qa": "header-button:login" },
            },
            [
              _c("span", { staticClass: "gfu-navbar-btn" }, [
                _vm._v(_vm._s(_vm.Resources.Layout.HeaderLoginLink)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "base-link",
            {
              staticClass: "gfu-navbar-item _screen--none _screen--flex-m",
              attrs: {
                url: _vm.createAccountUrl,
                "data-qa": "header-button:signup",
              },
            },
            [
              _c("span", { staticClass: "gfu-navbar-btn" }, [
                _vm._v(_vm._s(_vm.Resources.Layout.HeaderSignupLink)),
              ]),
            ]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
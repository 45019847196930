var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "checkbox-field",
    {
      staticClass: "_mt-3",
      attrs: {
        value: _vm.value,
        condensed: true,
        "display-name": _vm.refundAndCancellationPolicyFieldName,
        "label-class": "_flex",
        name: _vm.name,
        "data-qa": "crowdfunding-terms-agreement",
      },
      on: { input: _vm.onInput },
    },
    [
      _vm.hasCustomTerms
        ? _c(
            "localize",
            {
              attrs: {
                resource:
                  _vm.Resources.Checkout
                    .StoreCheckboxTermsAndConditionsAgreementMessage,
              },
            },
            [
              _c(
                "base-link",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    "format-key": "0",
                    url: _vm.customTosUrl,
                    target: "_blank",
                    "data-qa": "payment-options-link:CustomTermsOfService",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.Resources.Checkout.CheckoutTermsOfServiceLabel)
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "base-link",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    "format-key": "1",
                    url: _vm.storesTermsOfServiceUrl,
                    target: "_blank",
                    "data-qa": "payment-options-link:StoreTermsOfService",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.Resources.Checkout.StoreCheckoutTermsOfServiceLabel
                    )
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "localize",
            {
              attrs: {
                resource:
                  _vm.Resources.Checkout
                    .CheckboxTermsAndConditionsAgreementMessage,
              },
            },
            [
              _c(
                "base-link",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    "format-key": "0",
                    url: _vm.backersTermsOfServiceUrl,
                    target: "_blank",
                    "data-qa": "terms-of-service",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.Resources.Checkout.TermsAndConditionsForBackersLink
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "base-link",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    "format-key": "1",
                    url: _vm.refundAndCancellationPolicyUrl,
                    target: "_blank",
                    "data-qa": "refund-cancellation-policy",
                  },
                },
                [_vm._v(_vm._s(_vm.refundAndCancellationPolicyName))]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
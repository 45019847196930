var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "_mb-3" },
      [
        _c("base-icon", { attrs: { name: _vm.icon } }),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.title))]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "_rounded--common _bxsh--base _flexcol _bgc--white" },
      _vm._l(_vm.items, function (item, index) {
        return _c(
          "base-link",
          {
            key: index,
            staticClass:
              "_flexaicntr gfu-link--nofx _gap-2 _pa-2 _bdb--default _bc--disabled _pos-r gfu-ranking-list",
            attrs: { url: item.url, "data-qa": `ranking-entry:${index}` },
          },
          [
            _c(
              "div",
              {
                staticClass: "_flexaicntr _as-fs _gap-1 gfu-ranking-list-media",
              },
              [
                _c(
                  "div",
                  { staticClass: "gfu-ranking-list-media__icon" },
                  [
                    _c("span", { staticClass: "_fw-eb gfu-bt--b1" }, [
                      _vm._v("#" + _vm._s(index + 1)),
                    ]),
                    _vm._v(" "),
                    index < 3
                      ? _c("base-icon", {
                          staticClass: "_tc--light-gray _pos-a--cntr",
                          attrs: {
                            name: "wreath-laurel-icon",
                            "icon-namespace": "ga",
                            size: "5x",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("base-image", {
                  staticClass: "_rounded--common",
                  attrs: {
                    width: "64",
                    height: "64",
                    src: item.imageUrl,
                    alt: item.name,
                    loading: "lazy",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "gfu-ranking-list-description" }, [
              _c("div", { staticClass: "_flexcol _flex-basis-70" }, [
                _c(
                  "span",
                  {
                    staticClass: "_fw-eb gfu-bt--b1 _line-clamp-1",
                    attrs: { "data-qa": "project-name" },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "gfu-bt--caption _tc--lighter" }, [
                  _vm._v(_vm._s(item.description)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "_flexcol gfu-ranking-list-description__values _flex-basis-30",
                },
                [
                  _c(
                    "span",
                    { staticClass: "_tc--lighter gfu-bt--caption _ttu" },
                    [_vm._v(_vm._s(item.valueTitle))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "_tc--dark gfu-bt--b1",
                      attrs: { "data-qa": "ranking-value" },
                    },
                    [_vm._v(_vm._s(item.value))]
                  ),
                ]
              ),
            ]),
          ]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
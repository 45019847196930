var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-project-bottom-bar__content gfu-project-mini-wizard-expandable__summary gfu-project-mini-wizard-expandable__summary--compact",
      class: [
        {
          "gfu-project-mini-wizard-expandable__summary--alt-background":
            !_vm.hasSameItemsAsParent,
        },
      ],
    },
    [
      _c("div", { staticClass: "gfu-project-mini-wizard-expandable" }, [
        _c(
          "div",
          {
            staticClass: "_flexwrap _jc-sb _ai-c gfu-1of1 _ai-fs _mb-0",
            attrs: { "data-qa": "mini-wizard-expandable-summary" },
          },
          [
            _vm.hasSameItemsAsParent
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "gfu-bt--b1",
                      attrs: {
                        "data-qa": "mini-wizard-expandable-summary:Backer",
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.ProjectHome
                              .MiniWizardBackerWithoutNumberText
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gfu-1of1 _flexbtwn _ai-c" },
                    [
                      _c("div", [
                        _c("div", { staticClass: "gfu-bt--caption _ttu" }, [
                          _vm._v(
                            _vm._s(
                              _vm.Resources.ProjectHome
                                .MiniWizardExpandablePledgeValueText
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "gfu-project-mini-wizard__price _tc--inverse",
                            attrs: {
                              "data-qa":
                                "mini-wizard-expandable-summary:AlreadyPledged",
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$format(
                                    _vm.pledge.totalDiscountedValueConverted,
                                    "C2",
                                    _vm.pledge.currencySymbol
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.hasTaxHandlingEnabled
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "gfu-project-mini-wizard__caption _tc--inverse",
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.taxesSummaryLabel) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "gfu-btn gfu-btn--soft gfu-btn--narrow gfu-btn--transparent-inverse",
                          attrs: {
                            href: "",
                            "data-qa": "mini-wizard-expandable-summary:Upgrade",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onUpgradePledgeClick.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.Resources.ProjectHome
                                  .MiniWizardUpgradePledgeShortButton
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.currentPledge.manageShippingUrl
                        ? _c("go-to-checkout", {
                            attrs: {
                              "continue-url":
                                _vm.currentPledge.manageShippingUrl,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ onInvoke }) {
                                    return [
                                      _c(
                                        "base-link",
                                        {
                                          staticClass:
                                            "gfu-btn gfu-btn--soft gfu-btn--narrow gfu-btn--light-transparent",
                                          attrs: {
                                            url: _vm.currentPledge
                                              .manageShippingUrl,
                                            "data-qa":
                                              "mini-wizard-expandable-button:Complete",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return onInvoke.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.Resources.ProjectHome
                                                  .MiniWizardCompletePledgeShortButton
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              665836334
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "gfu-bt--caption gfu-1of1 _mb-1 _mt-1",
                      attrs: {
                        "data-qa": "mini-wizard-expandable-summary:Text",
                      },
                    },
                    [
                      _vm.pledge.parentOrder
                        ? _c("strong", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.Resources.format(
                                    _vm.Resources.ProjectHome
                                      .MiniWizardPledgeModifiedText,
                                    _vm.$format(
                                      _vm.pledge.parentOrder
                                        .totalDiscountedValueConverted,
                                      "C2",
                                      _vm.pledge.parentOrder.currencySymbol
                                    )
                                  )
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "_flexcol" },
                    [
                      _c(
                        "span",
                        { staticClass: "gfu-bt--caption _ttu" },
                        [
                          _c(
                            "tooltip",
                            {
                              attrs: {
                                text: _vm.Resources.Homepage
                                  .MiniWizardNoShippingTooltip,
                              },
                            },
                            [_c("span", { staticClass: "_fa _fa--info" })]
                          ),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.Resources.ProjectHome
                                  .MiniWizardCurrentPledgeLabel
                              ) +
                              "\n                    "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("display-currency-price", {
                        attrs: {
                          "effective-price":
                            _vm.pledge.totalDiscountedValueConverted,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "effective-price",
                            fn: function ({ effectivePrice }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gfu-project-mini-wizard__price _tc--inverse",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(effectivePrice) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.hasTaxHandlingEnabled
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gfu-project-mini-wizard__caption _tc--inverse",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.taxesSummaryLabel) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isPledgeEmpty && _vm.currentPledge.checkoutUrl
                    ? [
                        _c("go-to-checkout", {
                          attrs: {
                            "continue-url": _vm.currentPledge.checkoutUrl,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ onInvoke }) {
                                  return [
                                    _c(
                                      "base-link",
                                      {
                                        staticClass:
                                          "gfu-project-mini-wizard-expandable__cta gfu-btn gfu-btn--soft gfu-btn--narrow gfu-btn--accent",
                                        attrs: {
                                          url: _vm.currentPledge.checkoutUrl,
                                          disabled: _vm.hasSameItemsAsParent,
                                          "data-qa":
                                            "mini-wizard-expandable-button:Pledge",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return onInvoke.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.Resources.ProjectHome
                                                .MiniWizardSubmitChangesButton
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3639297736
                          ),
                        }),
                      ]
                    : _vm._e(),
                ],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "box",
    { staticClass: "gfu-box--huge" },
    [
      _c(
        "h2",
        {
          staticClass:
            "gfu-heading gfu-box__title gfu-hd gfu-hd--h1 gfu-heading--top-divider-center",
          attrs: { "data-qa": "page-title" },
        },
        [_vm._v("Unsubscribe confirmation")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_tac _mt-4" },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "_mb-1" }, [
                _vm._v("You will be unsubscribed from"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "_fw-b" }, [
                _vm._v(_vm._s(_vm.notificationTopicDisplayText)),
              ]),
              _vm._v(" email notifications\n            "),
              _vm.contextName
                ? [
                    _vm._v("\n                about\n                "),
                    _c("span", { staticClass: "_fw-b" }, [
                      _vm._v(_vm._s(_vm.contextName)),
                    ]),
                  ]
                : _vm._e(),
              _vm._v("\n            on Gamefound.\n            "),
              _c(
                "div",
                { staticClass: "_mt-1" },
                [
                  _vm._v("\n                Visit your "),
                  _c(
                    "base-link",
                    {
                      staticClass: "gfu-link gfu-link--accent",
                      attrs: {
                        url: _vm.subscriptionManagementUrl,
                        "data-qa": "communication-preferences-link",
                      },
                    },
                    [_vm._v("communication preferences")]
                  ),
                  _vm._v(
                    "\n                for more notification settings.\n            "
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "base-button",
            {
              staticClass: "_mt-4",
              attrs: { theme: "accent", "data-qa": "unsubscribe-button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.confirmUnsubscribe.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n            Unsubscribe\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { displayModeTypes } from '@/Utils/PwaUtility';
import { useLoadingIndicatorStore } from '@/Stores/Web/loadingIndicatorStore';
import { displayModeValues } from '@/Plugins/DisplayModes';
import { isValidUrl } from '@/Utils/UrlUtility';
import Log from '@/Utils/Log';

function registerEventListener({ selector, event, handler }) {
    function eventListener(e) {
        const target = selector ? e.target.closest(selector) : e.target;
        if (target) {
            handler(target, e);
        }
    }

    document.addEventListener(event, eventListener, true);

    return () => document.removeEventListener(event, eventListener, true);
}

const loadingBehaviourModule = {
    init() {
        registerEventListener({
            selector: 'a',
            event: 'click',
            handler: (target, e) => {
                try {
                    if (!isValidUrl(target.href)) return;

                    const currentPath = window.location.pathname;
                    const targetPath = new URL(target.href).pathname;

                    if (targetPath !== currentPath) {
                        pwaLoadingStrategy();
                    }
                }
                catch (error) {
                    Log.error(`Invalid href attribute on the target element:', ${target}`);
                }
            },
        });
    },
};

const imageContextMenuModule = {
    init() {
        registerEventListener({
            selector: 'img',
            event: 'contextmenu',
            handler: (target, e) => {
                e.preventDefault();
            },
        });
    },
};

function pwaLoadingStrategy() {
    const loadingIndicatorStore = useLoadingIndicatorStore();
    loadingIndicatorStore.startLoading();
}

const modeConfig = {
    [displayModeTypes.pwa]: {
        name: displayModeTypes.pwa,
        modules: [
            () => loadingBehaviourModule.init(),
            () => imageContextMenuModule.init(),
        ],
    },
    [displayModeTypes.web]: {
        name: displayModeTypes.web,
        modules: [
            () => {},
        ],
    },
};

function initializeMode(modeName) {
    const mode = modeConfig[modeName];
    if (!mode) {
        throw new Error(`Mode ${modeName} is not defined.`);
    }

    mode.modules.forEach(initializeModule => initializeModule());
}

const modeName = displayModeValues.currentModeName;
export default initializeMode(modeName);
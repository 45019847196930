var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
    _c(
      "div",
      { staticClass: "gfu-grid__cell gfu-1of1 gfu-1of2--s gfu-1of3--m" },
      [
        _c(
          "base-link",
          {
            staticClass:
              "gfu-btn gfu-btn--primary gfu-btn--soft gfu-btn--block",
            attrs: { url: _vm.actionUrl, "data-qa": _vm.qa },
          },
          [_c("span", [_vm._v(_vm._s(_vm.buttonText))])]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("hr", {
    staticClass: "gfu-base-divider",
    attrs: { "aria-orientation": "horizontal", role: "separator" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-box gfu-box--thick",
      attrs: {
        "data-qa": `project-update-single-page-box:${_vm.update.projectUpdateID}`,
      },
    },
    [
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
      _vm._v(" "),
      _c("project-update-banner", {
        attrs: { update: _vm.update, "show-info": false },
        scopedSlots: _vm._u(
          [
            !_vm.update.isPublished
              ? {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "tooltip",
                        {
                          attrs: {
                            text: _vm.Resources.ProjectUpdates
                              .UpdateStatusDraftParagraph,
                          },
                        },
                        [
                          _c("base-badge", { attrs: { theme: "light" } }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.Resources.ProjectUpdates
                                    .UpdateStatusDraftBadge
                                ) +
                                "\n                "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "date",
              fn: function () {
                return [
                  _vm.update.updatedAt && _vm.update.publishedAt
                    ? _c(
                        "tooltip",
                        {
                          staticClass: "_mr-1",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c(
                                      "localize",
                                      {
                                        attrs: {
                                          tag: "div",
                                          resource:
                                            _vm.Resources.ProjectUpdates
                                              .OriginallyPublished,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "_fw-eb _ffb",
                                            attrs: { "format-key": "0" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.publishedAtFormatted)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "localize",
                                      {
                                        attrs: {
                                          tag: "div",
                                          resource:
                                            _vm.Resources.ProjectUpdates
                                              .LastModified,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "_fw-eb _ffb",
                                            attrs: { "format-key": "0" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.updatedAtFormatted)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3740615238
                          ),
                        },
                        [_c("span", { staticClass: "_fa _fa--info" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.singleDateFormatted))]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_mt-3" },
        [
          _c("div", {
            staticClass: "gfu-richtext gfu-richtext--article",
            domProps: { innerHTML: _vm._s(_vm.update.content) },
          }),
          _vm._v(" "),
          _vm.update.pollID
            ? _c("poll", {
                staticClass: "_mt-6",
                attrs: {
                  "poll-i-d": _vm.update.pollID,
                  "data-qa": "project-update-single-page:Poll",
                },
                on: { error: _vm.handleError },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gfu-project-update__actions",
              attrs: { "data-qa": "project-update-single-page-actions" },
            },
            [
              _c(
                "div",
                { staticClass: "gfu-project-update__actions--left" },
                [
                  _c("like-action", {
                    attrs: {
                      enabled: _vm.isLoggedIn,
                      "is-liked": _vm.isLikeButtonHighlighted,
                      "likes-count": _vm.update.likesCount,
                    },
                    on: { like: _vm.toggleLikeUpdate },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gfu-project-update__actions--right" },
                [
                  _c("social-media-actions", {
                    staticClass: "gfu-project-update__actions-icons",
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
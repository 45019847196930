var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-nav gfu-nav--secondary _px-0 _screen--none _screen--m",
    },
    [
      _c("div", { staticClass: "gfu-layout-wrapper" }, [
        _c("div", { staticClass: "gfu-nav__content" }, [
          _c("div", { staticClass: "_flex _fo-y" }, [
            _c(
              "div",
              { staticClass: "gfu-nav__item _pl-1 _ai-c" },
              [
                _c(
                  "base-link",
                  {
                    staticClass: "gfu-link gfu-link-underline",
                    attrs: { url: _vm.homeUrl },
                  },
                  [
                    _c("span", { staticClass: "_mr-3 _fa _fa--lg _fa--home" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "gfu-link-underline__content" }, [
                      _vm._v(_vm._s(_vm.Resources.Common.BreadcrumbsHomeLink)),
                    ]),
                    _vm._v(" "),
                    _vm.isBlogPost
                      ? _c("span", {
                          staticClass: "_ml-3 _fa _fa--chevron-right",
                        })
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gfu-nav__item _ai-c" },
              [
                _c(
                  "base-link",
                  {
                    staticClass: "gfu-link gfu-link-underline",
                    attrs: { url: _vm.blogUrl },
                  },
                  [
                    _c("span", { staticClass: "gfu-link-underline__content" }, [
                      _vm._v(_vm._s(_vm.Resources.Common.BreadcrumbsBlogLink)),
                    ]),
                    _vm._v(" "),
                    _vm.isBlogPost
                      ? _c("span", {
                          staticClass: "_ml-3 _fa _fa--chevron-right",
                        })
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isBlogPost
              ? _c(
                  "div",
                  {
                    staticClass: "gfu-nav__item gfu-nav__item--selected _ai-c",
                  },
                  [
                    _c(
                      "base-link",
                      {
                        staticClass: "gfu-link",
                        attrs: { url: _vm.blogPostUrl },
                      },
                      [
                        _c("span", { staticClass: "gfu-nav__text" }, [
                          _vm._v(_vm._s(_vm.blogPostTitle)),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-layout-wrapper" }, [
    _c("div", { staticClass: "gfu-grid gfu-grid--gutter _flex _jc-c" }, [
      _c(
        "div",
        { staticClass: "gfu-grid__cell gfu-1of3--l gfu-4of6--m gfu-1of1" },
        [
          _c("box", { staticClass: "_mt-6 _mb-3", attrs: { thick: true } }, [
            _c(
              "h1",
              {
                staticClass:
                  "gfu-heading gfu-heading--soft gfu-box__title gfu-box__title--success",
              },
              [
                _vm._v(
                  _vm._s(_vm.Resources.ResetPassword.PasswordChangedHeader)
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "_tac" },
              [
                _c(
                  "localize",
                  {
                    attrs: {
                      resource:
                        _vm.Resources.ResetPassword.PasswordChangedParagraph,
                    },
                  },
                  [
                    _c(
                      "strong",
                      { attrs: { "format-key": "0" } },
                      [
                        _c(
                          "base-link",
                          {
                            staticClass: "gfu-link gfu-link--accent",
                            attrs: { url: _vm.loginUrl },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.ResetPassword.RedirectToLogInLink
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
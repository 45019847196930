var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-product-comparison-table",
      attrs: { tabindex: "-1", "data-qa": "compare-rewards-table" },
    },
    [
      _c(
        "div",
        { staticClass: "gfu-product-comparison-table__header" },
        [
          _c("sticky-table", {
            attrs: { "columns-count": _vm.columnsCount },
            scopedSlots: _vm._u([
              {
                key: "head",
                fn: function () {
                  return [
                    _vm.isLoaded
                      ? [
                          _c("th", {
                            staticClass:
                              "gfu-product-comparison-table-separator",
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.mappedColumnsData, function (item) {
                            return _c(
                              "product-comparison-table-column-header",
                              _vm._b(
                                {
                                  key: item.productID,
                                  attrs: { "data-qa": item.dataQa },
                                },
                                "product-comparison-table-column-header",
                                item,
                                false
                              )
                            )
                          }),
                        ]
                      : _c("progress-overlay", {
                          attrs: { visible: "", sticky: "" },
                        }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("sticky-table", {
        class: [{ "gfu-skeleton-sticky-table": _vm.isSkeletonVisible }],
        attrs: { "columns-count": _vm.columnsCount },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _vm.isLoaded
                  ? _vm._l(_vm.mappedRowsData, function (item) {
                      return _c(
                        "tr",
                        {
                          key: item.productID,
                          staticClass: "_contents",
                          attrs: {
                            "data-qa": `included-item-row-id:${item.productID}`,
                          },
                        },
                        [
                          _c(
                            "product-comparison-table-row-header",
                            _vm._b(
                              {},
                              "product-comparison-table-row-header",
                              item,
                              false
                            )
                          ),
                          _vm._v(" "),
                          _vm._l(item.values, function (value, index) {
                            return _c(
                              "product-comparison-table-value",
                              _vm._b(
                                {
                                  key: `product:${index}-${value}`,
                                  attrs: {
                                    "data-qa": `item-included-in:${index}`,
                                  },
                                },
                                "product-comparison-table-value",
                                value,
                                false
                              )
                            )
                          }),
                        ],
                        2
                      )
                    })
                  : _c("progress-overlay", {
                      attrs: { visible: "", sticky: "" },
                    }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
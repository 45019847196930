var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-card",
    {
      attrs: { "data-qa": `project-card-ID:${_vm.projectID}` },
      scopedSlots: _vm._u([
        {
          key: "media",
          fn: function () {
            return [
              _c(
                "base-link",
                {
                  staticClass: "gfu-embed gfu-embed--1x1",
                  attrs: { url: _vm.url, title: _vm.name },
                },
                [
                  _c("base-image", {
                    staticClass: "gfu-embed__item",
                    attrs: {
                      src: _vm.imageUrl,
                      alt: _vm.name,
                      loading: "lazy",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gfu-project-card__stamps" },
                [
                  _c(
                    "base-link",
                    {
                      staticClass:
                        "_rounded--input _pa-h _flex _bxsh--base _bgc--white",
                      attrs: { url: _vm.url, title: _vm.creatorName },
                    },
                    [
                      _c("base-image", {
                        attrs: {
                          src: _vm.creatorAvatarUrl,
                          alt: _vm.creatorName,
                          width: 36,
                          height: 36,
                          loading: "lazy",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_pa-3 _grow-1 _flexcol _jc-sb" },
        [
          _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "gfu-bt gfu-bt--caption _tc--light _mt-0 _mb-1 _mt-3",
              },
              [
                _c("strong", { staticClass: "_ttu _tc--accent" }, [
                  _vm._v(_vm._s(_vm.projectTypeLabel)),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "gfu-hd gfu-hd--h3 _line-clamp-2 _mb-3",
                attrs: { "data-qa": "project-card:ProjectName" },
              },
              [
                _c("base-link", { attrs: { url: _vm.url, title: _vm.name } }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "base-button",
            {
              attrs: {
                href: _vm.url,
                tag: "a",
                title: _vm.name,
                display: "block",
                theme: "accent",
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.Resources.ProjectHome.StoreCardButton) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
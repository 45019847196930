var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.bannerClassList,
        "_bxsh--base _bgc--white _rounded--common _jc-sb _pos-r _ai-c _ov-h _mb-7 _mt-5",
      ],
      attrs: { "data-qa": "compare-rewards-section" },
    },
    [
      _c(
        "div",
        { staticClass: "_flex-basis-70 _flexcol _gap-3" },
        [
          _c("base-heading", { attrs: { tag: "h2" } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.Resources.ProjectHome.CompareProductsBannerHeader) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "_tc--gray _mt-0" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.Resources.ProjectHome.CompareProductsBannerDescription
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "base-button",
                {
                  attrs: {
                    theme: "accent",
                    "data-qa": "compare-rewards-button",
                  },
                  on: { click: _vm.openCompareProductsModal },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.Resources.ProjectHome
                          .CompareProductsBannerCompareButtonText
                      ) +
                      "\n                "
                  ),
                  _c("base-icon", { attrs: { name: "angle-right" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("base-image", {
        class: _vm.imageClassList,
        attrs: {
          src: "/content/images/product-comparison.png",
          alt: "Product comparison",
        },
      }),
      _vm._v(" "),
      _vm.isProductComparisonModalVisible
        ? _c("product-comparison-modal", {
            attrs: { "project-id": _vm.projectID },
            on: { close: _vm.closeCompareProductsModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field",
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "field-description",
              fn: function () {
                return [_vm._t("field-description")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      },
      "field",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [
      _vm._v(" "),
      _c(
        "div",
        {
          class: ["gfu-field-image", _vm.imageFieldClass],
          attrs: { "data-qa": "image-field" },
        },
        [
          _c("input", {
            ref: "fileInput",
            staticClass: "gfu-field-image__input",
            attrs: {
              type: "file",
              accept:
                _vm.formatList && _vm.formatList.length
                  ? _vm.formatList.join(",")
                  : ".jpg,.jpeg,.png,.bmp,.webp",
              "data-qa": "image-field-input",
            },
            on: { change: _vm.onFileInputChange },
          }),
          _vm._v(" "),
          _vm.thumbUrl
            ? _c(
                "div",
                { staticClass: "gfu-field-image__img-wrapper" },
                [
                  _c(
                    "drop-area",
                    {
                      attrs: {
                        enabled: _vm.allowDrop && !_vm.readOnly,
                        name: _vm.name,
                        tabindex: "0",
                      },
                      on: { drop: _vm.uploadImage },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "gfu-field-image__actions" },
                        [
                          _c(
                            "dropdown-group",
                            {
                              ref: "dropdown",
                              attrs: {
                                fixed: false,
                                "collapse-on-scroll": true,
                              },
                            },
                            [
                              (_vm.uploadApiAction || _vm.uploadApiFunction) &&
                              !_vm.readOnly
                                ? _c("dropdown-link", {
                                    attrs: {
                                      icon: "sync",
                                      label: "Replace",
                                      "data-qa": "image-field-button:Replace",
                                    },
                                    on: { click: _vm.selectFileForUpload },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.allowDelete && !_vm.readOnly
                                ? _c("dropdown-link", {
                                    attrs: {
                                      icon: "trash-alt",
                                      label: "Delete",
                                      "data-qa": "image-field-button:Delete",
                                    },
                                    on: { click: _vm.deleteImage },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("img", {
                        class: ["gfu-field-image__image", _vm.imageClass],
                        attrs: {
                          src: _vm.cacheLessThumbUrl,
                          draggable: "false",
                          alt: _vm.displayName,
                          "data-qa": "image-field-image",
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          (_vm.uploadApiAction || _vm.uploadApiFunction) && !_vm.thumbUrl
            ? [
                _vm.allowDrop
                  ? _c(
                      "drop-area",
                      {
                        attrs: {
                          enabled: _vm.allowDrop && !_vm.readOnly,
                          preview: false,
                          name: _vm.name,
                          tabindex: "0",
                        },
                        on: {
                          drop: _vm.uploadImage,
                          click: _vm.selectFileForUpload,
                        },
                      },
                      [
                        !_vm.readOnly
                          ? [
                              _c("a", {
                                staticClass:
                                  "gfu-btn gfu-btn--circle gfu-btn--circle-big gfu-btn--primary _fa _fa--upload",
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.selectFileForUpload.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "gfu-field-image__input-title" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.allowDrop
                                          ? _vm.Resources.Layout
                                              .ImageFieldDropOrSelectLabel
                                          : _vm.Resources.Layout
                                              .ImageFieldSelectLabel
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "gfu-field-image__input-description",
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.formatList && _vm.formatList.length
                                          ? "format " +
                                              _vm.formatList.join(", ")
                                          : _vm.Resources.Layout
                                              .ImageFieldStandardFormatsAcceptedLabel
                                      ) +
                                      _vm._s(
                                        _vm.minHeight && _vm.minWidth
                                          ? ", minimum resolution: " +
                                              _vm.minWidth +
                                              "x" +
                                              _vm.minHeight
                                          : ""
                                      ) +
                                      _vm._s(
                                        _vm.ratio
                                          ? ", recommended ratio " + _vm.ratio
                                          : ""
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _c("a", {
                                staticClass:
                                  "gfu-btn gfu-btn--circle gfu-btn--circle-big gfu-btn--gray _fa _fa--ban",
                                attrs: { href: "#", disabled: "" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.selectFileForUpload.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "gfu-field-image__input-title gfu-field-image__input-title--disabled",
                                },
                                [_vm._v("This field is disabled.")]
                              ),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
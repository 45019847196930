var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "gfu-project-description-section gfu-project-description-section--regular gfu-layout-wrapper gfu-layout-wrapper--extranarrow gfu-4of5--l gfu-3of5--xl _pb-4",
      attrs: { "data-qa": `project-description-section:${_vm.sectionID}` },
    },
    [
      _c(
        "h2",
        {
          ref: "anchor",
          staticClass: "gfu-hd gfu-hd--h2 gfu-heading--top-divider _mb-1",
          attrs: {
            "data-qa": `project-description-section-title:${_vm.sectionHeader}`,
          },
        },
        [_vm._v(_vm._s(_vm.sectionHeader))]
      ),
      _vm._v(" "),
      _vm.rewardsTableEnabled ? _c("compare-rewards-banner") : _vm._e(),
      _vm._v(" "),
      _c("crowdfunding-reward-card-list", {
        attrs: { rewards: _vm.rewards, "checkout-url": _vm.checkoutUrl },
        on: { mounted: _vm.onMountedRewardCardList },
      }),
      _vm._v(" "),
      _vm.isCrowdfunding
        ? _c(
            "div",
            { staticClass: "_mt-3" },
            [
              _c("without-reward-section", {
                attrs: {
                  "project-id": _vm.projectID,
                  "min-amount": _vm.minAmount,
                  "max-amount": _vm.maxAmount,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sectionContent
        ? [
            _c(
              "h2",
              {
                staticClass: "gfu-hd gfu-hd--h2 _mt-4",
                attrs: {
                  "data-qa": "project-description-section:MoreInfoTitle",
                },
              },
              [_vm._v(_vm._s(_vm.Resources.Common.MoreInfoHeader))]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "_mt-2 gfu-richtext gfu-richtext--article",
              attrs: {
                "data-qa": "project-description-section:RewardsMoreInfo",
              },
              domProps: { innerHTML: _vm._s(_vm.sectionContent) },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
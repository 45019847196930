import { injectTypes } from '@/Types/InjectTypes';

export default {
    data() {
        return {
            isProcessingView: false,
        };
    },
    provide() {
        return {
            [injectTypes.startViewProcessing]: this.startViewProcessing,
            [injectTypes.stopViewProcessing]: this.stopViewProcessing,
            [injectTypes.isProcessingView]: this.isProcessingView,
            [injectTypes.withViewProcessing]: this.withViewProcessing,
        };
    },
    methods: {
        withViewProcessing(task) {
            return (...args) => {
                this.startViewProcessing();
                return task.apply(this, args)
                    .finally(() => this.stopViewProcessing());
            };
        },
        startViewProcessing() {
            this.isProcessingView = true;
            return Promise.resolve(this.isProcessingView);
        },
        stopViewProcessing() {
            this.isProcessingView = false;
            return Promise.resolve(this.isProcessingView);
        },
    },
};
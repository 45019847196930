import { useCurrencyStore } from '@/Stores/Web/currencyStore';
import Formatter from '@/Utils/Formatter';
import Config from '@/Config.js';

export const productComparisonThemes = {
    white: 'white',
    light: 'light',
    highlight: 'highlight',
};

export function getThemeClassName(theme, baseClassName) {
    return {
        [productComparisonThemes.highlight]: `${baseClassName}--highlight`,
        [productComparisonThemes.light]: `${baseClassName}--light`,
        [productComparisonThemes.white]: `${baseClassName}--white`,
    }[theme];
}

export function buildComparisonTableColumnHeaderModel(columns) {
    const yourPledgeModel = {
        productID: null,
        productName: 'Your Pledge',
        price: null,
        icon: 'shopping-cart',
        href: Config.projectNavigation.yourPledgeUrl,
    };

    const mappedData = columns.map(column => {
        const currencyStore = useCurrencyStore();
        return {
            productID: column.product.productID,
            productName: column.product.productName,
            price: Formatter.format(column.product.price / (currencyStore.currentCurrency?.relativeFactorWithCommission || 1), 'C2', currencyStore.currentCurrency?.symbol),
            imageUrl: column.product.mainImageUrl,
            isFeatured: column.product.isFeatured,
        };
    });

    return [yourPledgeModel, ...mappedData];
}

export function buildComparisonTableRowsData(data) {
    const currencyStore = useCurrencyStore();
    return data.rows.map(row => {
        return {
            productID: row.productID,
            productName: row.productName,
            price: row.isBuyable
                ? Formatter.format(row.price / (currencyStore.currentCurrency?.relativeFactorWithCommission || 1), 'C2', currencyStore.currentCurrency?.symbol)
                : null,
            imageUrl: row.mainImageUrl,
            values: buildValuesModel(data.columns, data.pledgeContents, row),
        };
    });
}

function buildValuesModel(columns, yourPledge, row) {
    const yourPledgeValue = yourPledge[row.productID] || 'times';
    const productValues = columns.map(column => {
        return column.contents[row.productID] || 'times';
    });

    return [yourPledgeValue, ...productValues];
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.model
        ? _c(
            "product-details",
            _vm._b(
              {
                scopedSlots: _vm._u(
                  [
                    !!_vm.model
                      ? {
                          key: "top",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "_screen--not-m" },
                                [
                                  _c(
                                    "base-link",
                                    {
                                      staticClass:
                                        "gfu-link gfu-link--block gfu-link--nofx _tc--accent _mb-6",
                                      attrs: { url: _vm.model.projectUrl },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "_fa _fa--chevron-left",
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "_ml-1" }, [
                                        _vm._v("go back to the project page"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "product-breadcrumb",
                                _vm._b(
                                  {
                                    staticClass:
                                      "_screen--none _screen--m _mb-6",
                                  },
                                  "product-breadcrumb",
                                  _vm.breadcrumbsModel,
                                  false
                                )
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              },
              "product-details",
              _vm.model,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _c("progress-overlay", { attrs: { visible: _vm.isProcessing } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.tileClassList },
    [
      _c(
        "base-link",
        {
          staticClass:
            "_flex _gap-2 _bgc--white _rounded--common _pa-o _bxsh--base gfu-link--nofx _grow-1",
          attrs: { url: _vm.targetUrl },
        },
        [
          _c("base-image", {
            staticClass: "_rounded--common",
            attrs: {
              width: "60",
              height: "60",
              src: _vm.imageUrl,
              loading: "lazy",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "_flexcol _grow-1" }, [
            _c(
              "div",
              { staticClass: "_flexcol _tc--light gfu-bt--overline" },
              [
                _c("notification-delivery-time", {
                  staticClass: "_shrink-0",
                  attrs: {
                    timestamp: _vm.createdAt,
                    "data-qa": "spotlight-notification-created-at",
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "_ttu",
                    attrs: { "data-qa": "spotlight-notification-type" },
                  },
                  [_vm._v(_vm._s(_vm.displayTitle))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "_tc--dark gfu-bt--caption _wbbw",
                attrs: { "data-qa": "spotlight-notification-project" },
              },
              [_vm._v(_vm._s(_vm.displayText))]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flex gfu-hd--h6 _gap-1 _tc--accent" },
    [
      _c(
        "base-link",
        {
          attrs: {
            url: _vm.author.profileUrl,
            "data-qa": "project-comment-action:ViewProfileLink",
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.Resources.Profile.ViewProfileAction) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.hasFollowAction
        ? [
            _c("span", {
              staticClass: "gfu-comment-user-profile-actions-separator",
            }),
            _vm._v(" "),
            _c("user-follow", {
              attrs: {
                "user-url-name": _vm.author.urlName,
                nickname: _vm.author.nickname,
                "user-id": _vm.author.userID,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ isFollowing, onFollow, onUnfollow }) {
                      return [
                        isFollowing
                          ? _c(
                              "a",
                              {
                                staticClass: "_ttl",
                                attrs: {
                                  href: "#",
                                  "data-qa":
                                    "project-comment-action:UnfollowLink",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return onUnfollow.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.Profile.UserUnfollowAction
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "_ttl",
                                attrs: {
                                  href: "#",
                                  "data-qa":
                                    "project-comment-action:FollowLink",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return onFollow.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.Resources.Profile.UserFollowAction)
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ],
                null,
                false,
                142715152
              ),
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
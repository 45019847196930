var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("footer-navigation", {
        attrs: {
          "current-page": _vm.Resources.Layout.TermsOfServiceLink,
          "group-type": "system",
        },
      }),
      _vm._v(" "),
      _c("main", { staticClass: "gfu-layout__main gfu-layout__main--narrow" }, [
        _c("div", { staticClass: "gfu-content-head" }, [
          _c(
            "h1",
            {
              staticClass: "gfu-hd gfu-hd--h1",
              attrs: { "data-qa": "page-title" },
            },
            [_vm._v(_vm._s(_vm.Resources.TermsOfService.PageHeader))]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gfu-box gfu-box--huge _mb-3 gfu-bt gfu-bt--b1" },
          [
            _c("p", [
              _vm._v(_vm._s(_vm.Resources.TermsOfService.PageDescription)),
            ]),
            _vm._v(" "),
            _vm._l(_vm.termsList, function (term) {
              return _c("div", { key: term.title }, [
                _c("h2", { staticClass: "gfu-hd gfu-hd--h2 _mt-2" }, [
                  _vm._v(_vm._s(term.title)),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "gfu-bullet-list" },
                  _vm._l(term.items, function (item) {
                    return _c(
                      "li",
                      { key: item.name, staticClass: "gfu-bullet-list__item" },
                      [
                        _c(
                          "base-link",
                          {
                            staticClass: "gfu-link gfu-link--accent",
                            attrs: { url: item.url },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      class: [
        "gfu-card",
        `gfu-card--scheme-${_vm.scheme}`,
        `gfu-card--orientation-${_vm.orientation}`,
        `gfu-card--media-size-${_vm.mediaSize}`,
      ],
    },
    [
      _vm.$scopedSlots.labels
        ? _c("div", { staticClass: "gfu-card__labels" }, [_vm._t("labels")], 2)
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: ["gfu-card__wrap", _vm.extendedWrapperClass] }, [
        _vm.$scopedSlots.media
          ? _c("div", { staticClass: "gfu-card__media" }, [_vm._t("media")], 2)
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.cardBodyClasses },
          [
            _vm.$scopedSlots.body
              ? _c(
                  "div",
                  { staticClass: "gfu-card__content" },
                  [_vm._t("body")],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._t("default"),
            _vm._v(" "),
            _vm.$scopedSlots.actions
              ? _c(
                  "div",
                  { staticClass: "gfu-card__actions" },
                  [_vm._t("actions")],
                  2
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length > 0
    ? _c(
        "div",
        { staticClass: "gfu-base-slider-indicators" },
        _vm._l(_vm.items, function (item, index) {
          return _c("a", {
            key: item.id,
            class: [
              "gfu-base-slider-indicators__indicator",
              { "is-active": item.isActive },
            ],
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onClick(index)
              },
            },
          })
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
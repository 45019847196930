var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-navbar-item gfu-navbar-dropdown" },
    [
      _c(
        "toggle-trigger",
        {
          staticClass: "gfu-navbar-btn",
          attrs: {
            tag: "button",
            "active-class": "is-visible",
            "container-full-screen-width": _vm.isMobile,
          },
        },
        [
          _c(
            "base-icon",
            {
              staticClass: "_pos-r",
              attrs: {
                name: "bell",
                size: "lg",
                "data-qa": "header-actions:Notifications",
              },
              on: { click: _vm.onNotificationIconClick },
            },
            [
              _vm.isNotificationBadgeVisible
                ? _c(
                    "span",
                    {
                      class: ["gfu-cart-badge"],
                      attrs: {
                        "data-qa":
                          "notification-icon:UnseenNotificationsNumber",
                      },
                    },
                    [_vm._v(_vm._s(_vm.notificationCount))]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "toggle-container",
        {
          staticClass: "gfu-navbar-dropdown__content",
          attrs: { "active-class": "is-visible", tabindex: "0" },
          on: { "active-change": _vm.onNotificationManagerOpen },
        },
        [
          _vm.isBaseBalloonVisible
            ? _c(
                "base-balloon",
                {
                  staticClass: "gfu-balloon--scrollable",
                  attrs: {
                    width: "wide",
                    position: "right",
                    "is-pointer-visible": false,
                    "data-qa": "notification-box-content",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "_flex _jc-sb _pa-2" },
                    [
                      _c("div", { staticClass: "gfu-balloon__top-title" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.Resources.Layout.WebPushLabel) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "base-link",
                        {
                          staticClass: "_tc--accent _tdn",
                          attrs: {
                            url: _vm.subscriptionManagementUrl,
                            "data-qa":
                              "notification-box-link:CommunicationPreferences",
                          },
                          on: { click: _vm.onLinkClick },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.Resources.Layout
                                  .CommunicationPreferencesLink
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isProcessing
                    ? _c(
                        "div",
                        { staticClass: "_px-2" },
                        _vm._l(_vm.placeholders, function (placeholder) {
                          return _c("skeleton-notification", {
                            key: placeholder,
                            staticClass: "_mt-2",
                          })
                        }),
                        1
                      )
                    : _c(_vm.component, {
                        tag: "component",
                        attrs: {
                          "active-change": "",
                          notifications: _vm.notifications,
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
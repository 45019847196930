import ServiceBase from './ServiceBase';

export default class UsersService extends ServiceBase {

    acceptAllCookies() {
        return this.request('post', 'users/acceptAllCookies');
    }

    getClientSecret() {
        return this.request('get', 'users/getClientSecret');
    }

    getCreditBalance(projectID) {
        return this.request('get', 'users/getCreditBalance', { params: { projectID } });
    }

    setAnalyticsCookie(accepted) {
        return this.request('post', 'users/setAnalyticsCookie', { accepted });
    }
    updateAddressData(userID, addressData) {
        return this.request('post', 'users/UpdateUserAddressData', { userID, addressData });
    }

    getUserAddressData(userID) {
        return this.request('get', 'users/GetUserAddressData', { params: { userID } });
    }

    getUserEmail(userID) {
        return this.request('get', 'users/GetUserEmail', { params: { userID } });
    }

    isNicknameAvailable(nickname) {
        return this.request('get', 'users/isNicknameAvailable', { params: { nickname } });
    }

    updateNickname(nickname) {
        return this.request('post', 'users/updateNickname', { nickname });
    }

    enableSocialFeatures({ nickname, isPublic }) {
        return this.request('post', 'users/enableSocialFeatures', { nickname, isPublic });
    }

    searchUserGroups(projectID, filters = {}, pageSize = null, pageIndex = null) {
        const params = {
            projectID,
            pageSize,
            pageIndex,
            ...filters,
        };

        return this.request('post', 'users/searchUserGroups', params);
    }

    updateUserProfile({
        bio,
        facebookUrl,
        fullName,
        instagramUrl,
        isPublic,
        nickname,
        twitterUrl,
        youtubeUrl,
    }) {
        return this.request('post', 'users/updateUserProfile', {
            bio,
            facebookUrl,
            fullName,
            instagramUrl,
            isPublic,
            nickname,
            twitterUrl,
            youtubeUrl,
        });
    }

    completeUserAccount(user, activationKey, projectID, autoLogin = false, autoLoginPersist = false) {
        const data = {
            ...user,
            activationKey,
            autoLogin,
            autoLoginPersist,
            projectID,
        };

        return this.request('post', 'users/CompleteUserAccount', data);
    }

    revertImpersonation() {
        return this.request('post', 'users/RevertImpersonation');
    }

    getMyProjects(filters = {}) {
        const params = {
            ...filters || {},
        };

        return this.request('get', 'users/getMyProjects', { params: { ...params } });
    }

    getAdminUserContext() {
        return this.request('get', 'users/getAdminUserContext');
    }

    getUserContext() {
        return this.request('get', 'users/getUserContext');
    }

    getCurrentUserProfileSettings() {
        return this.request('get', 'users/getCurrentUserProfileSettings');
    }

    followProject(projectID, section) {
        return this.request('post', 'users/followProject', { projectID, section });
    }

    unfollowProject(projectID, email) {
        return this.request('post', 'users/unfollowProject', { projectID, email });
    }

    validateUnfollow(projectID) {
        return this.request('get', 'users/validateUnfollow', { params: { projectID } });
    }

    requestResetPassword(email) {
        return this.request('post', 'users/requestResetPassword', { email });
    }

    resetPassword(newPassword, passwordResetKey) {
        return this.request('post', 'users/resetUserPassword', { newPassword, passwordResetKey });
    }

    updateUserPassword(userPassword) {
        return this.request('post', 'users/updateUserPassword', userPassword);
    }

    deleteAccount(password) {
        return this.request('post', 'users/deleteAccount', { password });
    }

    updateUserEmail(userEmailModel) {
        return this.request('post', 'users/updateUserEmail', userEmailModel);
    }

    revertUserEmailUpdate(userID) {
        return this.request('post', 'users/revertUserEmailUpdate', { userID });
    }

    getUserPrivacySettings(userID) {
        return this.request('get', 'users/getUserPrivacySettings', { params: { userID } });
    }

    updateUserPrivacySettings(userPrivacySettings) {
        return this.request('post', 'users/updateUserPrivacySettings', userPrivacySettings);
    }

    createAccount(email, reCaptchaToken, reCaptchaAction, returnUrl) {
        return this.request('post', 'users/createUserAccount', { email, returnUrl, reCaptchaToken, reCaptchaAction });
    }

    login(email, password, reCaptchaToken, reCaptchaAction, returnUrl) {
        return this.request('post', 'users/loginUser', { email, password, returnUrl, reCaptchaToken, reCaptchaAction });
    }

    completeTwoFactorAuthentication({ userEmail, authenticationData, returnUrl }) {
        return this.request('post', 'users/completeTwoFactorAuthentication', { userEmail, authenticationData, returnUrl });
    }

    restartTwoFactorAuthentication({ userEmail }) {
        return this.request('post', 'users/restartTwoFactorAuthentication', { userEmail });
    }

    getCreditCards() {
        return this.request('get', 'users/getUserCreditCards');
    }

    deleteCreditCard(userCreditCardID) {
        const params = {
            userCreditCardID,
        };
        return this.request('post', 'users/deleteUserCreditCard', params);
    }

    externalSignIn(accessToken, providerType, returnUrl, firstName, lastName) {
        const params = {
            accessToken,
            providerType,
            returnUrl,
            firstName,
            lastName,
        };

        return this.request('post', 'users/userSignInWithExternalIdentityProvider', params);
    }

    banUser(userID, reason) {
        const params = {
            userID,
            reason,
        };

        return this.request('post', 'users/banUser', params);
    }

    unbanUser(userID) {
        return this.request('post', 'users/unbanUser', { userID });
    }

    setProjectArchiveStatus(projectID, isArchived) {
        return this.request('post', 'users/setProjectArchiveStatus ', { projectID, isArchived });
    }

    getUserSubscriptions() {
        return this.request('get', 'users/getUserSubscriptions');
    }

    updateUserSubscription( { creatorID = null, preference, preferredDigestFrequency,  projectID = null, topic } ) {
        return this.request('post', 'users/updateUserSubscription', { creatorID, preference, preferredDigestFrequency, projectID, topic });
    }

    updateUserAvatar(avatarHash) {
        return this.request('post', 'users/updateUserAvatar', { avatarHash });
    }
}

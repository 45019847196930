var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-project-summary-action gfu-media" }, [
    _c("span", {
      staticClass:
        "gfu-project-summary-action__side _fa _fa--unlock _tc--accent",
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gfu-media__body" },
      [
        _c("h5", { staticClass: "gfu-hd gfu-hd--h4 _ma-0" }, [
          _vm._v("All stretch goals unlocked"),
        ]),
        _vm._v(" "),
        _c(
          "base-link",
          {
            staticClass: "gfu-link gfu-link--accent",
            attrs: {
              url: _vm.stretchGoalsUrl,
              "data-qa": "view-stretch-goals-link",
            },
          },
          [_vm._v("view stretch goals list")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
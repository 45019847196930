var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "th",
    {
      class: ["gfu-product-comparison-table-row-header", _vm.themeClass],
      attrs: { "data-qa": "set-item-row-header" },
    },
    [
      _c(
        "context-link",
        _vm._b(
          { staticClass: "gfu-product-comparison-table-row-header__link" },
          "context-link",
          _vm.contextLinkProps,
          false
        ),
        [
          _c("base-image", {
            attrs: {
              src: _vm.imageUrl,
              alt: _vm.productName,
              width: _vm.imageSize,
              height: _vm.imageSize,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gfu-product-comparison-table-row-header__desc" },
            [
              _c(
                "span",
                {
                  staticClass: "_line-clamp-1",
                  attrs: { "data-qa": "row-header-props:Name" },
                },
                [_vm._v(_vm._s(_vm.productName))]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: _vm.descriptionClassList,
                  attrs: { "data-qa": "row-header-props:Price" },
                },
                [_vm._v(_vm._s(_vm.formattedPrice))]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "th",
    {
      ref: "row",
      class: ["gfu-product-comparison-table-column-header", _vm.themeClass],
      style: `left: ${_vm.formattedOffset}`,
      attrs: { "data-qa": _vm.dataQa },
    },
    [
      _c(
        "context-link",
        _vm._b(
          { staticClass: "gfu-product-comparison-table-column-header__link" },
          "context-link",
          _vm.contextLinkProps,
          false
        ),
        [
          _vm.isFeatured
            ? _c(
                "div",
                { staticClass: "gfu-product-card__tag-container _ml-3" },
                [
                  _c("base-badge", { staticClass: "gfu-product-card__tag" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.Resources.ProjectHome.FeaturedProductBadge) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.icon
            ? _c("base-icon", {
                staticClass: "gfu-product-comparison-table-column-header__icon",
                attrs: { name: _vm.icon, size: "3x", "has-base-class": false },
              })
            : _c("base-image", {
                attrs: {
                  src: _vm.imageUrl,
                  alt: _vm.productName,
                  width: 108,
                  height: 108,
                },
              }),
          _vm._v(" "),
          _c(
            "div",
            {
              class: ["_flexcol _pa-1 _grow-1 _jc-sb", _vm.textAlignmentClass],
            },
            [
              _c(
                "span",
                {
                  staticClass: "_line-clamp-2",
                  attrs: { "data-qa": "column-header-props:Name" },
                },
                [_vm._v(_vm._s(_vm.productName))]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "gfu-bt--caption _fw-n",
                  attrs: { "data-qa": "column-header-props:Price" },
                },
                [_vm._v(_vm._s(_vm.formattedPrice))]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
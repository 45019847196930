var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-project-summary-action gfu-media gfu-media--middle",
      attrs: { "data-qa": "project-summary-credits" },
    },
    [
      _c("base-icon", {
        staticClass: "gfu-project-summary-action__side _tc--accent",
        attrs: { name: "money-bill-wave" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-media__body" },
        [
          _c("h5", { staticClass: "gfu-hd gfu-hd--h4 _ma-0" }, [
            _vm._v(_vm._s(_vm.formattedCreditsText)),
          ]),
          _vm._v(" "),
          _c(
            "base-link",
            {
              staticClass: "gfu-link gfu-link--accent",
              attrs: { url: _vm.backProjectUrl },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.Resources.ProjectHome.ProjectSummaryBoxSpendCreditsLink
                  ) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { defineStore } from 'pinia';

export const useLoadingIndicatorStore = defineStore({
    id: 'loadingIndicator',
    state: () => ({
        isLoading: false,
    }),
    actions: {
        startLoading() {
            this.isLoading = true;
        },
        stopLoading() {
            this.isLoading = false;
        },
    },
});
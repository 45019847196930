var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_flex" },
    [
      _c("img", {
        class: _vm.classNames,
        attrs: {
          src: _vm.avatarUrl,
          alt: _vm.alt,
          width: _vm.avatarSize,
          height: _vm.avatarSize,
        },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
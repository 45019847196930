var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "td",
    {
      ref: "value",
      class: [
        "gfu-product-comparison-table-value",
        _vm.themeClass,
        _vm.textClass,
      ],
      style: `left: ${_vm.formattedOffset}`,
    },
    [
      _vm.hasValue
        ? [_vm._v(_vm._s(_vm.value))]
        : _c("base-icon", { attrs: { name: _vm.value, size: "xs" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
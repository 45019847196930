var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "gfu-project-update__header",
        attrs: { "data-qa": "project-update-header" },
      },
      [
        _vm._t("header", function () {
          return [
            _c(
              "span",
              {
                staticClass: "gfu-project-update__counter",
                attrs: { "data-qa": "project-update-sequenceNumber" },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.Resources.format(
                        _vm.Resources.ProjectUpdates.UpdateSequenceNumberLabel,
                        _vm.update.sequenceNumber
                      )
                    ) +
                    "\n            "
                ),
              ]
            ),
          ]
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "gfu-project-update__date",
            attrs: { "data-qa": "project-update-date" },
          },
          [
            _vm._t("date"),
            _vm._v(" "),
            _vm.update.isPinned
              ? _c("span", {
                  staticClass:
                    "_fa _fa--thumbtack gfu-project-update__date-icon",
                })
              : _vm._e(),
          ],
          2
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "h1",
      {
        staticClass: "gfu-project-update__title",
        attrs: { "data-qa": "project-update-title" },
      },
      [_vm._v("\n        " + _vm._s(_vm.update.title) + "\n    ")]
    ),
    _vm._v(" "),
    _vm.update.hasImage
      ? _c("img", {
          attrs: {
            width: "100%",
            height: "auto",
            src: _vm.update.imageUrl,
            alt: _vm.Resources.format(
              _vm.Resources.ProjectUpdates.UpdateSequenceNumberLabel,
              _vm.update.sequenceNumber
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "gfu-project-update__abstract",
        attrs: { "data-qa": "project-update-abstract" },
      },
      [_vm._v("\n        " + _vm._s(_vm.update.abstract) + "\n    ")]
    ),
    _vm._v(" "),
    _vm.showInfo
      ? _c("div", { staticClass: "gfu-project-update__info" }, [
          _c("div", { staticClass: "gfu-project-update__info-left" }, [
            _c(
              "div",
              { staticClass: "gfu-project-update__info-icon-wrapper" },
              [
                _c(
                  "div",
                  {
                    staticClass: "gfu-project-update__info-icon",
                    class: {
                      "_tc--accent":
                        _vm.update.userInfo &&
                        _vm.update.userInfo.likedAt !== null,
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "_fa _fa--thumbs-up",
                      attrs: { title: _vm.Resources.ProjectUpdates.LikeAction },
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.update.likesCount) + "\n                "
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gfu-project-update__info-right" },
            [
              _c(
                "base-link",
                {
                  staticClass:
                    "gfu-btn gfu-btn--primary gfu-btn--block gfu-btn--soft",
                  attrs: {
                    url: _vm.update.projectUpdateUrl,
                    "data-qa": "project-update-btn:ReadMore",
                  },
                },
                [
                  _c("span", { staticClass: "_fa _fr _fa--chevron-right" }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.Resources.Common.ReadMoreActionButton) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("image-field", {
    staticClass: "_w-100 _tac",
    attrs: {
      name: "avatarImageUrl",
      "image-class": "gfu-avatar gfu-avatar-image",
      "image-field-class": "_jc-c",
      "upload-api-function": _vm.uploadApiFunction,
      "thumb-url": _vm.model.avatarImagePreview,
      "data-qa": "account-settings:AvatarImage",
    },
    on: { "image-uploaded": _vm.onImageChanged, error: _vm.handleError },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
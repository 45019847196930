var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "gfu-hd gfu-hd--h2" }, [
        _vm._v(_vm._s(_vm.Resources.Faq.FaqPageHeader)),
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
        _vm._v(_vm._s(_vm.Resources.PaymentSuccess.StoreFaqSectionHeaderItem1)),
      ]),
      _vm._v(" "),
      _c(
        "localize",
        {
          attrs: {
            resource:
              _vm.Resources.PaymentSuccess.StoreFaqSectionParagraphItem1,
          },
        },
        [
          _c(
            "base-link",
            {
              staticClass: "gfu-link gfu-link--accent",
              attrs: {
                "format-key": "0",
                url: _vm.yourOrderUrl,
                "data-qa": "pledge-success-link:YourOrder",
              },
            },
            [_vm._v(_vm._s(_vm.Resources.PaymentSuccess.YourOrderPageLink))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("h4", { staticClass: "gfu-hd gfu-hd--h4 _mt-3" }, [
        _vm._v(_vm._s(_vm.Resources.PaymentSuccess.StoreFaqSectionHeaderItem2)),
      ]),
      _vm._v(" "),
      _c(
        "localize",
        {
          attrs: {
            resource:
              _vm.Resources.PaymentSuccess.StoreFaqSectionParagraphItem2,
          },
        },
        [
          _vm.faqEnabled
            ? _c(
                "base-link",
                {
                  staticClass: "gfu-link gfu-link--accent",
                  attrs: {
                    "format-key": "0",
                    url: _vm.links.faqUrl,
                    "data-qa": "pledge-success-link:Faq",
                  },
                },
                [_vm._v(_vm._s(_vm.Resources.Faq.FaqPageHeader))]
              )
            : _c("span", { attrs: { "format-key": "0" } }, [
                _vm._v(_vm._s(_vm.Resources.Faq.FaqPageHeader)),
              ]),
          _vm._v(" "),
          _c(
            "base-link",
            {
              staticClass: "gfu-link gfu-link--accent",
              attrs: {
                "format-key": "1",
                url: _vm.links.contactUrl,
                "data-qa": "pledge-success-link:ContactForm",
              },
            },
            [
              _vm._v(
                _vm._s(_vm.Resources.PaymentSuccess.FaqSectionContactFormLink)
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
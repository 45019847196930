var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": `order-summary:${_vm.pledge.orderPublicID}` } },
    [
      _c("div", { staticClass: "gfu-flex__container" }, [
        _vm.isExpandable
          ? _c(
              "a",
              {
                staticClass: "_mr-3 gfu-table-meta-more _shrink-0",
                attrs: { href: "#", "data-qa": "your-pledge-button:ShowMore" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("expand")
                  },
                },
              },
              [
                _c("span", { class: _vm.expandButtonClasses }),
                _vm._v(" "),
                _c("span", { staticClass: "gfu-table-meta-more__label" }, [
                  _vm._v(_vm._s(_vm.Resources.YourPledge.ShowMoreButton)),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { class: _vm.projectMetaClasses }, [
          _c("div", [
            _c("div", { staticClass: "_flex _jc-sb _mb-2" }, [
              _c("div", { staticClass: "gfu-flex__container--vertical" }, [
                _c(
                  "h2",
                  {
                    staticClass: "gfu-heading",
                    attrs: { "data-qa": "your-pledge:orderPublicID" },
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.orderPublicIdHeaderText) +
                        "\n                        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h3",
                  { staticClass: "_tc--lighter _fw-n gfu-bt--caption _my-0" },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.Resources.format(
                            _vm.Resources.YourPledge.PledgeDateSubheader,
                            _vm.pledge.createDate
                          )
                        ) +
                        "\n                        "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "_flex gfu-table-meta__items _row-gap-2 _col-gap-3 _jc-fs",
            },
            [
              _vm.isOrderStageVisible
                ? _c(
                    "div",
                    {
                      staticClass:
                        "_pa-2 _br-2 _rounded--common _bg-color-light _flexinline",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.Resources.YourPledge.OrderStageLabel) +
                          "\n                    "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "_ml-1 gfu-hd",
                          attrs: { "data-qa": "your-pledge:OrderStage" },
                        },
                        [_vm._v(_vm._s(_vm.orderStageName))]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPaymentStatusVisible
                ? _c(
                    "div",
                    {
                      staticClass:
                        "_pa-2 _br-2 _rounded--common _bg-color-light _flexinline",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.Resources.YourPledge.OrderPaymentStatusLabel
                          ) +
                          "\n                    "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "_ml-1 gfu-hd",
                          attrs: {
                            "data-qa": "your-pledge:OrderPaymentStatus",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.orderPaymentStatusName) +
                              "\n                        "
                          ),
                          _c(
                            "tooltip",
                            {
                              key: _vm.orderPaymentStatusTooltip,
                              attrs: { text: _vm.orderPaymentStatusTooltip },
                            },
                            [
                              _c("a", {
                                staticClass:
                                  "gfu-link gfu-link--nofx _fa _fa--info",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.orderStatusExplanation
            ? _c(
                "div",
                { staticClass: "_mt-3" },
                [
                  _vm.orderStatusExplanation.type === "notificator"
                    ? _c(
                        "base-notificator",
                        { attrs: { "can-close": false } },
                        [
                          _c(
                            "div",
                            { staticClass: "_flex _ai-c _gap-6" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.orderStatusExplanation.text) +
                                  "\n\n                        "
                              ),
                              _vm.orderStatusExplanation.button
                                ? _c(
                                    "base-button",
                                    {
                                      staticClass: "_shrink-0 _ml-a",
                                      attrs: {
                                        enabled: _vm.isOrderValid,
                                        theme: "transparentInverse",
                                        "data-qa":
                                          "your-pledge-notification-button:ResumePayment",
                                      },
                                      on: { click: _vm.ctaTriggered },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.orderStatusExplanation.button
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.orderStatusExplanation.type === "tick"
                    ? _c(
                        "div",
                        { staticClass: "gfu-flex__container" },
                        [
                          _c("circle-icon", {
                            staticClass: "_mr-2 _shrink-0",
                            attrs: {
                              icon: "check",
                              theme: "success",
                              size: "large",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "_flex gfu-flex__container--vertical gfu-flex__item--grow _tc--dark",
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.orderStatusExplanation.text)),
                              ]),
                              _vm._v(" "),
                              _vm.orderStatusExplanation.sub
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.orderStatusExplanation.sub)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderStatusExplanation.next
                                ? _c("div", [
                                    _c("div", {
                                      staticClass:
                                        "_bt-2 _pt-2 _mt-2 _bc--light",
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "_fw-eb" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.Resources.YourPledge
                                            .OrderStageNextHintTitle
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.orderStatusExplanation.next)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.labels.length > 0
        ? _c(
            "div",
            {
              staticClass: "gfu-table-meta__labels",
              attrs: { "data-qa": "your-pledge-label" },
            },
            _vm._l(_vm.labels, function (label) {
              return _c("base-badge", { key: label }, [
                _vm._v("\n            " + _vm._s(label) + "\n        "),
              ])
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
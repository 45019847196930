var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-view", { staticClass: "gfu-layout-wrapper" }, [
    _c("div", { staticClass: "gfu-grid gfu-grid--gutter" }, [
      _c(
        "div",
        {
          staticClass:
            "gfu-grid__cell gfu-1of1 gfu-4of6--m gfu-left-1of6--m gfu-1of1 gfu-10of12--l gfu-left-1of12--l gfu-1of2--xl gfu-left-3of12--xl",
        },
        [
          _c(
            "div",
            {
              staticClass: "gfu-box _mt-6 _mb-3",
              attrs: { "data-qa": "welcome-user-message-header" },
            },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "gfu-heading gfu-heading--soft gfu-box__title gfu-box__title--success _mb-3",
                  attrs: { "data-qa": "join-project-confirmation:header" },
                },
                [_vm._v(_vm._s(_vm.header))]
              ),
              _vm._v(" "),
              _vm.welcomeUserMessage
                ? _c("div", {
                    staticClass: "gfu-box__section gfu-richtext",
                    attrs: {
                      "data-qa":
                        "join-project-confirmation:welcome-user-message",
                    },
                    domProps: { innerHTML: _vm._s(_vm.welcomeUserMessage) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isV2Project
                ? [
                    _c("p", { staticClass: "_tac" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.Resources.format(
                              _vm.Resources.JoinProject
                                .ManageYourPledgeParagraph,
                              _vm.projectName
                            )
                          ) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "_tac _mt-6" },
                      [
                        _c(
                          "base-link",
                          {
                            staticClass:
                              "gfu-btn gfu-btn--accent gfu-btn--wide gfu-btn--soft",
                            attrs: { url: _vm.urlYourPledge },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.JoinProject.ViewYourPledgeButton
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _vm.hasUserCreditBalance
                      ? _c(
                          "div",
                          { staticClass: "_tac _mb-6" },
                          [
                            _c(
                              "localize",
                              {
                                attrs: {
                                  resource:
                                    _vm.Resources.JoinProject
                                      .CreditsGrantedToAccountParagraph,
                                },
                              },
                              [
                                _c("span", { attrs: { "format-key": "0" } }, [
                                  _vm._v(_vm._s(_vm.projectName)),
                                ]),
                                _vm._v(" "),
                                _c("strong", { attrs: { "format-key": "1" } }, [
                                  _vm._v(_vm._s(_vm.userCreditBalance)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.predefinedCartItems.length
                      ? [
                          _c("div", { staticClass: "_tac _mb-3" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.Resources.format(
                                    _vm.Resources.JoinProject
                                      .PledgeCreatedByCreatorIntro,
                                    _vm.projectName
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.predefinedCartItems,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "gfu-table gfu-table__row--dark gfu-product__actions__item",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "gfu-table__row _pr-0 _tal",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "gfu-table__cell gfu-table__cell--centered gfu-table__cell--image _mr-3",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "gfu-table__thumb" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: item.imageUrl,
                                                  alt: item.productName,
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "gfu-table__cell gfu-table__cell--grow _tal",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "gfu-table__text--big",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.quantity) + "x "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "gfu-table__text--strong gfu-table__text--big",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(item.productName) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.continueDirectlyToHome
                      ? _c(
                          "div",
                          { staticClass: "_tac _mt-6" },
                          [
                            _c(
                              "base-link",
                              {
                                staticClass:
                                  "gfu-btn gfu-btn--accent gfu-btn--wide gfu-btn--soft",
                                attrs: { url: _vm.urlProjectHome },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.JoinProject
                                      .ContinueToProjectHomeButton
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "form",
                          { attrs: { method: "post", action: _vm.actionForm } },
                          [
                            _c(
                              "div",
                              { staticClass: "_mb-6" },
                              [
                                !_vm.cannotDiscardCard
                                  ? [
                                      _c("div", { staticClass: "_tac _mb-3" }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.Resources.JoinProject
                                                .PledgeCreatedByCreatorFollowUpParagraph
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "_tac _mb-3" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-field gfu-field--inline _mr-3",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "gfu-field__toggle gfu-field-toggle gfu-field-toggle--radio",
                                              },
                                              [
                                                _c("input", {
                                                  staticClass:
                                                    "gfu-field-toggle__input",
                                                  attrs: {
                                                    type: "radio",
                                                    name: "keepItems",
                                                    value: "true",
                                                    checked: "true",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gfu-field-toggle__label",
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "gfu-field-toggle__icon",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "gfu-field-toggle__label-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.Resources
                                                              .JoinProject
                                                              .KeepPledgeCreatedByCreatorOption
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-field gfu-field--inline",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "gfu-field__toggle gfu-field-toggle gfu-field-toggle--radio",
                                              },
                                              [
                                                _c("input", {
                                                  staticClass:
                                                    "gfu-field-toggle__input",
                                                  attrs: {
                                                    type: "radio",
                                                    name: "keepItems",
                                                    value: "false",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gfu-field-toggle__label",
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "gfu-field-toggle__icon",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "gfu-field-toggle__label-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.Resources
                                                              .JoinProject
                                                              .DiscardPledgeCreatedByCreatorOption
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.enableAdditionalCommunicationConsent
                                  ? [
                                      _c("div", { staticClass: "_tac _mb-3" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-field gfu-field--inline",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "gfu-field__toggle gfu-field-toggle",
                                              },
                                              [
                                                _c("input", {
                                                  staticClass:
                                                    "gfu-field-toggle__input",
                                                  attrs: {
                                                    type: "checkbox",
                                                    name: "externalCommunicationConsent",
                                                    value: "true",
                                                  },
                                                  domProps: {
                                                    checked: _vm.isChecked,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gfu-field-toggle__label _tal",
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass:
                                                        "gfu-field-toggle__icon",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "gfu-field-toggle__label-text",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _c(
                                                            "span",
                                                            [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .Resources
                                                                      .JoinProject
                                                                      .CreatorEmailCommunicationConsentLabel
                                                                  ) +
                                                                  "\n                                                    "
                                                              ),
                                                              _c(
                                                                "tooltip",
                                                                {
                                                                  attrs: {
                                                                    text: _vm.tooltipText,
                                                                  },
                                                                },
                                                                [
                                                                  _c("em", [
                                                                    _c("span", {
                                                                      staticClass:
                                                                        "_fa _fa--info",
                                                                    }),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "_tac" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "gfu-btn gfu-btn--accent gfu-btn--wide gfu-btn--soft",
                                  attrs: { type: "submit" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.Resources.JoinProject
                                        .ContinueToProjectHomeButton
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                  ],
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-qa": "checkout-footer" } },
    [
      _vm.showAdyenPaymentInfo
        ? [
            _c(
              "div",
              {
                staticClass: "gfu-bt _mt-3",
                attrs: { "data-qa": "checkout-footer:GamefoundInfo" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.Resources.Checkout
                        .CheckoutFooterCompanyInformationParagraph
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("h6", { staticClass: "gfu-hd gfu-hd--h4 _mt-3 _mb-2" }, [
              _vm._v(
                _vm._s(
                  _vm.Resources.Checkout
                    .CheckoutFooterProcessingPaymentsSectionTitle
                )
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "gfu-bt",
                attrs: { "data-qa": "checkout-footer:AdyenInfo" },
              },
              [
                _c(
                  "localize",
                  {
                    attrs: {
                      resource:
                        _vm.Resources.Checkout
                          .CheckoutFooterProcessingPaymentsSectionParagraph,
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "gfu-link gfu-link--accent",
                        attrs: {
                          "format-key": "0",
                          href: "https://www.adyen.com/",
                          target: "_blank",
                          rel: "noopener noreferrer",
                        },
                      },
                      [_vm._v("Adyen")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isDccEnabled
        ? [
            _c("h6", { staticClass: "gfu-hd gfu-hd--h4 _mt-3 _mb-2" }, [
              _vm._v(
                _vm._s(_vm.Resources.Checkout.CheckoutFooterDccSectionTitle)
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gfu-bt" },
              [
                _c(
                  "localize",
                  {
                    attrs: {
                      resource:
                        _vm.Resources.Checkout
                          .CheckoutFooterDccSectionParagraph,
                    },
                  },
                  [
                    _c("span", { attrs: { "format-key": "0" } }, [
                      _vm._v(_vm._s(_vm.commissionRateCalculated)),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "base-link",
                  {
                    staticClass: "gfu-link gfu-link--accent",
                    attrs: {
                      target: "_blank",
                      url: _vm.articleUrl,
                      rel: "noreferrer nofollow",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.Resources.Checkout
                          .CheckoutFooterDccSectionReadMoreLink
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("h6", { staticClass: "gfu-hd gfu-hd--h4 _mt-3 _mb-2" }, [
        _vm._v(
          _vm._s(
            _vm.Resources.Checkout.CheckoutFooterGamefoundLegalSectionTitle
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "gfu-bt",
          attrs: { "data-qa": "checkout-footer:StoreInfo" },
        },
        [_vm._v("\n        " + _vm._s(_vm.effectiveDisclaimer) + "\n    ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
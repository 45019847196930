var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "_flex _gap-2 _py-2 gfu-animation-pulse",
      attrs: { "data-qa": "skeleton-object" },
    },
    [
      _c("skeleton-image", { attrs: { size: 96 } }),
      _vm._v(" "),
      _c("skeleton-line", { staticClass: "_grow-1", attrs: { height: 60 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
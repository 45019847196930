var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "gfu-summary-list",
      attrs: { "data-qa": "order-summary-wrapper" },
    },
    [
      _vm._t("header"),
      _vm._v(" "),
      _c(
        "div",
        { class: _vm.summaryListClasses },
        [
          _vm._t("description"),
          _vm._v(" "),
          _vm._l(_vm.summaryListData, function (summaryListItem, index) {
            return [
              summaryListItem.isDivider
                ? _c("div", {
                    key: `${summaryListItem.title}-${index}`,
                    staticClass: "gfu-summary-list-wrapper__divider",
                  })
                : _c(
                    "summary-list-item",
                    _vm._b(
                      { key: `${summaryListItem.title}-${index}` },
                      "summary-list-item",
                      summaryListItem,
                      false
                    )
                  ),
            ]
          }),
          _vm._v(" "),
          _vm._t("actions"),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
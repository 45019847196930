var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classes }, [
    _c("img", { attrs: { src: _vm.imageUrl, alt: _vm.alt } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "gfu-header-mobile__box-group" },
    [
      _vm._l(_vm.navigation, function (item) {
        return [
          item.isRestricted
            ? _c(
                "tooltip",
                {
                  key: `${item.name}-tooltip`,
                  attrs: {
                    text: item.restrictionReason,
                    theme: "light",
                    "vertical-position": "down",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gfu-link gfu-link--nofx gfu-link--block gfu-link--disabled",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "gfu-header-mobile__box-group-item gfu-header-mobile__box-group-item--disabled",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.name) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _c(
                "base-link",
                {
                  key: item.name,
                  staticClass: "gfu-link gfu-link--nofx gfu-link--block",
                  attrs: { url: item.url },
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "gfu-header-mobile__box-group-item",
                        {
                          "gfu-header-mobile__box-group-item--selected":
                            item.isActive,
                        },
                      ],
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.name) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "gfu-layout__main gfu-layout__main--narrow" },
    [
      _c("h1", { staticClass: "gfu-heading gfu-heading--main _mt-6" }, [
        _vm._v(_vm._s(_vm.Resources.Account.CreateCreatorAccountHeader)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-subheading gfu-subheading--main _mb-3" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.Resources.Account.CreateCreatorAccountSubheader) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gfu-box gfu-box--thick _mt-3" }, [
        _c("h2", { staticClass: "gfu-heading _mt-3" }, [
          _vm._v(
            _vm._s(_vm.Resources.Account.CreateCreatorAccountStepOneText) +
              "\n            "
          ),
          _vm.isAuthenticated
            ? _c("span", {
                staticClass: "gfu-heading__icon--accent _fa _fa--check-circle",
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gfu-subheading gfu-subheading--wizard _mb-3" },
          [
            _vm.isAuthenticated
              ? [
                  _c(
                    "localize",
                    {
                      attrs: {
                        resource:
                          _vm.Resources.Account
                            .CreateCreatorAccountStepOneParagraph1,
                      },
                    },
                    [
                      _c("strong", { attrs: { "format-key": "0" } }, [
                        _vm._v(_vm._s(_vm.userEmail)),
                      ]),
                    ]
                  ),
                ]
              : [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.Resources.Account
                          .CreateCreatorAccountStepOneParagraph2
                      ) +
                      "\n            "
                  ),
                ],
          ],
          2
        ),
        _vm._v(" "),
        !_vm.isAuthenticated
          ? _c("div", { staticClass: "gfu-flex__container--l" }, [
              _c(
                "div",
                {
                  staticClass:
                    "_tac _pa-3 _flexb50--l gfu-box__left gfu-box__left--separator",
                },
                [
                  _c("div", {}, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.Resources.Account
                            .CreateCreatorAccountLoginOptionParagraph
                        ) +
                        "\n                    "
                    ),
                    _c(
                      "p",
                      [
                        _c(
                          "base-link",
                          {
                            staticClass:
                              "gfu-btn gfu-btn--accent gfu-btn--wide gfu-btn--soft gfu-btn--compact",
                            attrs: {
                              url: _vm.loginUrlWithReturn,
                              "data-qa": "create-account-button:LogIn",
                            },
                          },
                          [_vm._v(_vm._s(_vm.Resources.Account.LogInButton))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "_tac _pa-3 _flexb50--l gfu-box__separator gfu-box__left",
                },
                [
                  _c("div", { staticClass: "gfu-box--centered" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.Resources.Account
                            .CreateCreatorAccountSingUpOptionParagraph
                        ) +
                        "\n                    "
                    ),
                    _c(
                      "p",
                      [
                        _c(
                          "base-link",
                          {
                            staticClass:
                              "gfu-btn gfu-btn--primary gfu-btn--wide gfu-btn--soft gfu-btn--compact",
                            attrs: {
                              url: _vm.createAccountUrlWithReturn,
                              "data-qa": "create-account-button:SignUp",
                            },
                          },
                          [_vm._v(_vm._s(_vm.Resources.Account.SignUpButton))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { class: ["_pt-3", _vm.disabledClass] }, [
          _c(
            "h2",
            {
              staticClass: "gfu-heading",
              attrs: { "data-qa": "create-account:PickNameHeader" },
            },
            [
              _vm._v(
                _vm._s(_vm.Resources.Account.CreateCreatorAccountStepTwoText)
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gfu-subheading gfu-subheading--wizard _mb-1" },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.Resources.Account.CreateCreatorAccountStepTwoParagraph
                  ) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("create-creator-account-form", {
                attrs: {
                  "creator-url": _vm.creatorUrl,
                  "read-only": !_vm.isAuthenticated,
                },
                on: { continue: _vm.onContinue },
              }),
              _vm._v(" "),
              _c("progress-overlay", {
                attrs: { sticky: true, visible: _vm.isProcessing },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMiniWizardVisible
    ? _c("mini-wizard-sticky", {
        attrs: {
          "is-processing": _vm.isProcessing,
          "data-qa": "project-mini-wizard",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm.isPledgePlaced
                    ? _c(
                        "mini-wizard-sticky-header",
                        {
                          attrs: {
                            variant: _vm.isEditingPledge
                              ? "primary"
                              : "default",
                            "has-logo": _vm.isUnmodifiedPledge,
                            "data-qa": "mini-wizard-header",
                          },
                        },
                        [
                          _vm.isEditingPledge
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "_mb-3 _fw-b",
                                    attrs: {
                                      "data-qa": "mini-wizard-header:Text",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.Resources.ProjectHome
                                            .MiniWizardModifyingPledgeTitle
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c("base-icon", {
                                      staticClass: "_mr-h",
                                      attrs: { name: "exclamation-circle" },
                                    }),
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.Resources.ProjectHome
                                            .MiniWizardModifyingPledgeHint
                                        ) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("mini-wizard-sticky-credits"),
                              ]
                            : [
                                _c("mini-wizard-sticky-backer"),
                                _vm._v(" "),
                                _c("mini-wizard-sticky-amount"),
                                _vm._v(" "),
                                _c("mini-wizard-sticky-credits"),
                                _vm._v(" "),
                                _vm.isAwaitingPaymentPledge
                                  ? _c(
                                      "base-button",
                                      {
                                        staticClass: "_mt-2",
                                        attrs: {
                                          tag: "a",
                                          display: "block",
                                          width: "narrow",
                                          theme: "transparentInverse",
                                          href: _vm.currentPledge
                                            .resumeOrderUrl,
                                          "data-qa":
                                            "mini-wizard-button:ResumePayment",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.Resources.ProjectHome
                                                .MiniWizardResumePaymentButton
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.canEditPledge
                                  ? _c(
                                      "base-button",
                                      {
                                        staticClass: "_mt-2",
                                        attrs: {
                                          type: "button",
                                          display: "block",
                                          width: "narrow",
                                          theme: "transparentInverse",
                                          disabled: _vm.isProcessing,
                                          "data-qa":
                                            "mini-wizard-button:EditPledge",
                                        },
                                        on: { click: _vm.onEditPledge },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.Resources.ProjectHome
                                                .MiniWizardEditPledgeButton
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isCollapsible
                                  ? _c("mini-wizard-sticky-collapsible", {
                                      attrs: {
                                        "is-collapsed": _vm.isCollapsed,
                                      },
                                      on: {
                                        "toggle-collapsed":
                                          _vm.onToggleCollapsed,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFeaturedRewardVisible
                    ? _c("mini-wizard-sticky-reward")
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.effectiveIsCollapsed,
                          expression: "!effectiveIsCollapsed",
                        },
                      ],
                      staticClass: "_pa-3",
                    },
                    [
                      _vm.isFeaturedRewardVisible
                        ? _c(
                            "divider-labeled",
                            { staticClass: "_tc--disabled _mb-3" },
                            [
                              _c("span", { staticClass: "_tc--gray" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.Resources.ProjectHome
                                      .MiniWizardDividerLabel
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.allItemsRemoved
                        ? _c("mini-wizard-items-content", {
                            attrs: {
                              "addons-section": _vm.addonsSection,
                              "can-delete-items": _vm.canDeleteItems,
                              "delete-method": _vm.onDeleteItem,
                              "is-unmodified-pledge": _vm.isUnmodifiedPledge,
                              "pledge-date": _vm.pledgeDate,
                              "rewards-section": _vm.rewardsSection,
                              "show-addons": !_vm.isPledgeEmpty,
                              "show-delete-button": false,
                              "show-rewards": !_vm.allChargeableItemsRemoved,
                              "show-rewards-header":
                                !_vm.isFeaturedRewardVisible,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.removedAllItemsLabel
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "_ba-1 _rounded--input _bc--input _pa-2 _tac",
                              attrs: { "data-qa": "mini-wizard-item:Empty" },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.removedAllItemsLabel) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.promoItem && _vm.isPledgeEmpty
                        ? _c("project-promo-item", {
                            staticClass:
                              "gfu-1of1 gfu-bt gfu-bt--caption _mt-3",
                            attrs: {
                              "image-size": 36,
                              "data-qa":
                                "mini-wizard-expandable-project-promo-item",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            !_vm.isPledgeEmpty || _vm.allItemsRemoved
              ? {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "mini-wizard-sticky-footer",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.effectiveIsCollapsed,
                              expression: "!effectiveIsCollapsed",
                            },
                          ],
                          staticClass: "_flexcol _gap-2",
                        },
                        [
                          !_vm.isUnmodifiedPledge
                            ? [
                                _vm.isEditingPledge
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "_flexwrap _jc-sb _ai-c",
                                        attrs: {
                                          "data-qa": "mini-wizard:YourPledge",
                                        },
                                      },
                                      [
                                        _c(
                                          "base-heading",
                                          {
                                            attrs: {
                                              tag: "h6",
                                              variant: "light",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.Resources.ProjectHome
                                                    .MiniWizardModifyingPledgeCostBeforeLabel
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("mini-wizard-sticky-price", {
                                          attrs: {
                                            price:
                                              _vm.pledge.parentOrder
                                                .totalDiscountedValueConverted,
                                            "data-qa":
                                              "mini-wizard:OriginalPledge",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "_flexwrap _jc-sb _ai-c",
                                    attrs: {
                                      "data-qa": "mini-wizard:YourPledge",
                                    },
                                  },
                                  [
                                    _c(
                                      "base-heading",
                                      {
                                        attrs: { tag: "h6", variant: "light" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(_vm.yourPledgeLabel) +
                                            "\n                        "
                                        ),
                                        _c(
                                          "tooltip",
                                          {
                                            attrs: {
                                              text: _vm.Resources.ProjectHome
                                                .MiniWizardNoShippingTooltip,
                                            },
                                          },
                                          [
                                            _c("base-icon", {
                                              attrs: { name: "info" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("mini-wizard-sticky-price", {
                                      attrs: {
                                        price:
                                          _vm.pledge
                                            .totalDiscountedValueConverted,
                                        "data-qa":
                                          "mini-wizard:YourPledgePrice",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.hasTaxHandlingEnabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-project-mini-wizard__caption _flex-basis-100",
                                            attrs: {
                                              "data-qa":
                                                "mini-wizard-item-warning:Tax",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(_vm.taxesSummaryLabel) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("mini-wizard-sticky-credits", {
                                  attrs: { "has-accented-amount": true },
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.promoItem
                            ? _c("project-promo-item", {
                                staticClass: "gfu-1of1 gfu-bt gfu-bt--caption",
                                attrs: {
                                  "image-size": 36,
                                  "data-qa":
                                    "mini-wizard-expandable-project-promo-item",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canSubmit
                            ? _c("mini-wizard-sticky-submit", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.submitLabel) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canCancel
                            ? _c(
                                "base-button",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.currentPledge.canCancelPledge,
                                    theme: "accent",
                                    display: "block",
                                    width: "narrow",
                                    "data-qa":
                                      "mini-wizard-button:CancelPledge",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.onCancelPledge.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .MiniWizardCancelPledgeButton
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isRevertChangesVisible
                            ? _c("div", { staticClass: "_tac" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "gfu-link gfu-link--accent",
                                    attrs: {
                                      type: "button",
                                      "data-qa":
                                        "mini-wizard-button:RevertChanges",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onRevertChanges.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.Resources.ProjectHome
                                            .MiniWizardRevertChangesButton
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isManageYourPledgeVisible
                            ? _c(
                                "div",
                                { staticClass: "_tac" },
                                [
                                  _c(
                                    "base-link",
                                    {
                                      staticClass: "gfu-link gfu-link--accent",
                                      attrs: {
                                        url: _vm.currentPledge.cartDetailsUrl,
                                        "data-qa":
                                          "mini-wizard-button:ManageYourPledge",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.cartDetailsLabel) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
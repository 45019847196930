var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "_flexcol _ai-c" }, [
    _c("div", { staticClass: "_mt-2 _gap-2 _flex" }, [
      _c(
        "div",
        { staticClass: "_flexcol _jc-sb _gap-1" },
        _vm._l(_vm.mappedSortActions, function (buttons) {
          return _c(
            "base-button",
            {
              key: buttons.type,
              attrs: {
                theme: "white",
                shape: "circle",
                shadow: "tight",
                "data-qa": "left-arrow-button",
              },
              on: {
                click: function ($event) {
                  return _vm.changeHash(buttons.type, _vm.directions.prev)
                },
              },
            },
            [
              _c("base-icon", {
                staticClass: "_tc--accent",
                attrs: { name: "chevron-left", size: "lg" },
              }),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "_grow-1 _pos-r _flex _ai-c _jc-c" }, [
        _c(
          "div",
          { staticClass: "gfu-avatar gfu-avatar-image" },
          _vm._l(_vm.avatarModel, function (image) {
            return _c("base-image", {
              key: image.type,
              staticClass: "_pos-a--cntr",
              attrs: {
                src: `/content/images/avatar/${image.type}/${image.pieceNumber}.png`,
                height: "140",
                width: "140",
                alt: "avatar",
                "data-qa": `image-piece:${image.type}`,
              },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "_flexcol _jc-sb _gap-1" },
        _vm._l(_vm.mappedSortActions, function (buttons) {
          return _c(
            "base-button",
            {
              key: buttons.type,
              attrs: {
                theme: "white",
                shape: "circle",
                shadow: "tight",
                "data-qa": `right-arrow-button:${buttons.type}`,
              },
              on: {
                click: function ($event) {
                  return _vm.changeHash(buttons.type, _vm.directions.next)
                },
              },
            },
            [
              _c("base-icon", {
                staticClass: "_tc--accent",
                attrs: { name: "chevron-right", size: "lg" },
              }),
            ],
            1
          )
        }),
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "gfu-link gfu-link--accent _tac _mt-1",
        attrs: { href: "#" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.randomizeAvatar.apply(null, arguments)
          },
        },
      },
      [
        _c("base-icon", {
          staticClass: "_tc--accent",
          attrs: { name: "dice" },
        }),
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.Resources.AccountSettings
                .AvatarManagerModalRandomizeAvatarLabel
            ) +
            "\n    "
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
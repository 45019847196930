var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.rewardsSection
    ? _c("mini-wizard-sticky", {
        attrs: {
          "is-processing": _vm.isProcessing,
          "data-qa": "project-mini-wizard",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "mini-wizard-sticky-header",
                    {
                      attrs: {
                        "has-logo": true,
                        "data-qa": "mini-wizard-header",
                      },
                    },
                    [
                      _c("mini-wizard-sticky-backer"),
                      _vm._v(" "),
                      _c("mini-wizard-sticky-amount"),
                      _vm._v(" "),
                      _c("mini-wizard-sticky-credits"),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "_pa-3" },
                    [
                      !_vm.allItemsRemoved
                        ? _c("mini-wizard-items-content", {
                            attrs: {
                              rewards: _vm.rewards,
                              addons: _vm.addons,
                              "is-unmodified-pledge": _vm.isUnmodifiedPledge,
                              "can-delete-items": _vm.canDeleteItems,
                              "show-addons": !_vm.isPledgeEmpty,
                              "pledge-date": _vm.pledgeDate,
                              "show-delete-button": false,
                              "show-rewards-header": false,
                              "delete-method": _vm.onDeleteItem,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.allItemsRemoved
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "_ba-1 _rounded--input _bc--input _pa-2 _tac",
                              attrs: { "data-qa": "mini-wizard-item:Empty" },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.Resources.ProjectHome
                                      .MiniWizardRemovedAllItemsText
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  !_vm.isPledgeEmpty || _vm.allItemsRemoved
                    ? _c(
                        "mini-wizard-sticky-footer",
                        { staticClass: "_flexcol _gap-2" },
                        [
                          !_vm.hasSameItemsAsParent
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "_flexwrap _jc-sb _ai-c",
                                    attrs: {
                                      "data-qa": "mini-wizard:YourPledge",
                                    },
                                  },
                                  [
                                    _c(
                                      "base-heading",
                                      {
                                        attrs: { tag: "h6", variant: "light" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.Resources.ProjectHome
                                                .MiniWizardModifyingPledgeCostBeforeLabel
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("mini-wizard-sticky-price", {
                                      attrs: {
                                        price:
                                          _vm.pledge.parentOrder
                                            .totalDiscountedValueConverted,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "_flexwrap _jc-sb _ai-c",
                                    attrs: {
                                      "data-qa": "mini-wizard:YourPledge",
                                    },
                                  },
                                  [
                                    _c(
                                      "base-heading",
                                      {
                                        attrs: { tag: "h6", variant: "light" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.Resources.ProjectHome
                                                .MiniWizardModifyingPledgeCostAfterLabel
                                            ) +
                                            "\n                        "
                                        ),
                                        _c(
                                          "tooltip",
                                          {
                                            attrs: {
                                              text: "Does not include shipping.",
                                            },
                                          },
                                          [
                                            _c("base-icon", {
                                              attrs: { name: "info" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("mini-wizard-sticky-price", {
                                      attrs: {
                                        price:
                                          _vm.pledge
                                            .totalDiscountedValueConverted,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.hasTaxHandlingEnabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-project-mini-wizard__caption",
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(_vm.taxesSummaryLabel) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.isProjectInFundingPhase &&
                                _vm.pledge.creditDiscountConverted
                                  ? _c(
                                      "div",
                                      { staticClass: "_flexwrap _jc-sb _ai-c" },
                                      [
                                        _c(
                                          "base-heading",
                                          {
                                            attrs: {
                                              tag: "h6",
                                              variant: "light",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.Resources.ProjectHome
                                                    .MiniWizardCreditDiscountLabel
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gfu-project-mini-wizard__price _tc--accent",
                                            attrs: {
                                              "data-qa":
                                                "mini-wizard:UsedCreditsAmount",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(_vm.usedCreditsAmount) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.rewardsSection && _vm.canAddNewItems
                            ? _c(
                                "router-link",
                                {
                                  staticClass:
                                    "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary",
                                  attrs: {
                                    to: {
                                      name: "page-section",
                                      params: {
                                        section: _vm.rewardsSection.urlName,
                                      },
                                    },
                                    "data-qa":
                                      "mini-wizard-button:AddMoreItems",
                                  },
                                },
                                [
                                  _c("base-icon", {
                                    staticClass: "_fl",
                                    attrs: { name: "plus" },
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .MiniWizardUpgradePledgeButton
                                      ) +
                                      "\n            "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.addonsSection && _vm.canAddNewItems
                            ? _c(
                                "router-link",
                                {
                                  staticClass:
                                    "gfu-btn gfu-btn--soft gfu-btn--block gfu-btn--narrow gfu-btn--primary",
                                  attrs: {
                                    to: {
                                      name: "page-section",
                                      params: {
                                        section: _vm.addonsSection.urlName,
                                      },
                                    },
                                    "data-qa": "mini-wizard-button:PickAddons",
                                  },
                                },
                                [
                                  _c("base-icon", {
                                    staticClass: "_fl",
                                    attrs: { name: "plus" },
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.Resources.ProjectHome
                                          .MiniWizardMoreAddOnsButton
                                      ) +
                                      "\n            "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("mini-wizard-sticky-submit", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.Resources.ProjectHome
                                    .MiniWizardCompletePledgeButton
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.isUnfinishedOrder &&
                          _vm.isEditingPledge &&
                          !_vm.hasSameItemsAsParent
                            ? _c("div", { staticClass: "_tac" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "gfu-link gfu-link--accent",
                                    attrs: {
                                      "data-qa":
                                        "mini-wizard-button:RevertChanges",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onRevertChanges.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.Resources.ProjectHome
                                            .MiniWizardRevertChangesButton
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.isPledgePlaced && !_vm.hasSameItemsAsParent) ||
                          !_vm.isPledgeEmpty
                            ? _c(
                                "div",
                                { staticClass: "_tac" },
                                [
                                  _c(
                                    "base-link",
                                    {
                                      staticClass: "gfu-link gfu-link--accent",
                                      attrs: {
                                        url: _vm.currentPledge.cartDetailsUrl,
                                        "data-qa":
                                          "mini-wizard-button:ManageYourPledge",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.Resources.ProjectHome
                                              .MiniWizardManagePledgeButton
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1040661438
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
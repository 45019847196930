var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      class: [
        "gfu-tag",
        _vm.themeClass,
        _vm.colorClass,
        {
          "gfu-tag--solid": _vm.isSolid,
          "gfu-tag--compact": _vm.isCompact,
        },
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
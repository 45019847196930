import AsyncHelpers from '@/Mixins/AsyncHelpers';
import analytics from '@/Analytics';
import { getPermission, isBrowserSupported, iOSPushPermissionRequest, getIOSPushToken, states } from '@/Utils/WebPushUtility';
import { apiClient } from '@/Clients/ApiClient';
import PushNotificationService from '@/Services/PushNotificationsService';
import { mapState } from 'pinia';
import { mapStores } from 'pinia';
import { useUserContextStore } from '@/Stores/Web/userContextStore.js';
import { usePushNotificationStore } from '@/Stores/Web/pushNotificationStore';
import { iOSPushCapability, getIOSPushPermissionState } from '@/Utils/WebPushUtility';

export default {
    data: () => ({
        shouldActivateIOSPush: false,
    }),
    mixins: [AsyncHelpers],
    computed: {
        hasIOSPushCapability() {
            return iOSPushCapability();
        },
        currentState() {
            if (!this.isAuthenticated) {
                return states.unauthorized;
            }
            if (!this.hasPushNotificationsEnabled && this.notificationPermissionValue === states.granted) {
                return states.default;
            }

            return this.notificationPermissionValue;
        },
        notificationPermissionValue() {
            if ('Notification' in window) {
                return Notification.permission;
            }

            return states.unauthorized;
        },
        ...mapState(useUserContextStore, ['hasPushNotificationsEnabled', 'isAuthenticated', 'userId']),
        ...mapStores(usePushNotificationStore),
    },
    methods: {
        onEnablePush() {
            if (this.hasIOSPushCapability) {
                this.shouldActivateIOSPush = true;
                iOSPushPermissionRequest();
            }
            else {
                this.enableWebPushNotificationsAsync();
            }
        },
        onIOSPushPermissionStateChange(event) {
            if (!this.isAuthenticated) {
                this.pushNotificationStore.setPushPermissionStatus(states.unauthorized);
            }
            else if (event && event.detail) {
                this.pushNotificationStore.pushPermissionStatus = event.detail;
                switch (event.detail) {
                case 'notDetermined':
                    this.pushNotificationStore.setPushPermissionStatus(states.default);
                    break;
                case 'denied':
                    this.pushNotificationStore.setPushPermissionStatus(states.denied);
                    break;
                case 'authorized':
                case 'ephemeral':
                case 'provisional':
                    if (!this.hasPushNotificationsEnabled) {
                        this.pushNotificationStore.setPushPermissionStatus(states.default);
                        break;
                    }

                    this.pushNotificationStore.setPushPermissionStatus(states.granted);
                    break;
                case 'unknown':
                default:
                    this.pushNotificationStore.setPushPermissionStatus(states.denied);
                    break;
                }
            }
        },
        onIOSPushPermissionRequest(event) {
            if (event && event.detail) {
                switch (event.detail) {
                case 'granted':
                    getIOSPushToken();
                    break;
                default:
                    // permission denied
                    this.onModalClose();
                    break;
                }
            }
        },
        handleIOSPushToken(event) {
            if (event && event.detail && this.shouldActivateIOSPush) {
                const token = event.detail;
                this.handleNotificationEnableSuccess(token);
            }
        },
        handleNotificationEnableSuccess(token) {
            this.enablePushNotifications(token);
            this.$notify.popupSuccess(this.Resources.Layout.WebPushConfirmationSuccessMessage);
            this.pushNotificationStore.setPushPermissionStatus(states.granted);
            analytics.track('enable-push', {
                section: this.section,
                pushEnabled: true,
                userId: this.userId,
            });
            this.onModalClose();
        },
        iOSPushPermissionState() {
            getIOSPushPermissionState();
        },
        onModalClose() {},
        async enableWebPushNotificationsAsync() {
            try {
                this.isProcessing = true;
                const isSupported = await isBrowserSupported();
                if (isSupported) {
                    const token = await getPermission();
                    switch (token) {
                    case undefined:
                        return;
                    case false:
                        this.pushNotificationStore.setPushPermissionStatus(states.denied);
                        this.onModalClose();
                        return;
                    default:
                        this.handleNotificationEnableSuccess(token);
                    }
                }
                else {
                    throw new Error(this.Resources.Layout.WebPushNotSupportedErrorMessage);
                }
            }
            catch (error) {
                this.handleError(error);
                analytics.track('enable-push', {
                    section: this.section,
                    pushEnabled: false,
                    userId: this.userId,
                });
            }
            finally {
                this.isProcessing = false;
            }
        },
        handleError(error) {
            this.$notify.popupServerError(error);
        },
    },
    created() {
        const pushNotificationService = new PushNotificationService(apiClient);
        this.enablePushNotifications = this.withProcessing(pushNotificationService
            .enablePushNotifications.bind(pushNotificationService));
    },
    mounted() {
        if (this.hasIOSPushCapability) {
            window.addEventListener('push-permission-state', this.onIOSPushPermissionStateChange);
            window.addEventListener('push-permission-request', this.onIOSPushPermissionRequest);
            window.addEventListener('push-token', this.handleIOSPushToken);
            this.iOSPushPermissionState();
        }
        else {
            this.pushNotificationStore.setPushPermissionStatus(states[this.currentState]);
        }
    },
    beforeDestroy() {
        if (this.hasIOSPushCapability) {
            window.removeEventListener('push-permission-request', this.onIOSPushPermissionRequest);
            window.removeEventListener('push-token', this.handleIOSPushToken);
            window.removeEventListener('push-permission-state', this.onIOSPushPermissionStateChange);
        }
    },
};
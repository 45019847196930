var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("project-summary-box", { attrs: { borderless: true } }, [
    _c("div", { staticClass: "_flexbtwn _ai-c" }, [
      _c(
        "a",
        {
          staticClass: "_tc--accent _ml-a",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.close.apply(null, arguments)
            },
          },
        },
        [
          _vm._v(
            "\n            " + _vm._s(_vm.Resources.Common.ModalCloseButton)
          ),
          _c("span", {
            staticClass: "_fa _fa--base _fa--lg _fa--times _ml-1 _fr",
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "gfu-project-summary-details-list" }, [
      _c(
        "div",
        { staticClass: "gfu-project-summary-details-list__item _py-1 _mb-2" },
        [
          _c("div", { staticClass: "gfu-hd gfu-hd--h3 _flex _mb-1" }, [
            _c("span", {
              staticClass: "_ga _ga--gamefound-outline _tc--accent _mr-1",
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.Resources.ProjectHome
                    .ProjectSummaryLearnMoreAboutCrowdfundingHeader
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gfu-bt gfu-bt--b2" },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.Resources.ProjectHome
                      .ProjectSummaryLearnMoreAboutCrowdfundingText
                  ) +
                  "\n                "
              ),
              _c(
                "base-link",
                {
                  staticClass: "gfu-link gfu-link--nofx gfu-link--accent",
                  attrs: { url: _vm.crowdfundingGuideUrl },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm.Resources.ProjectHome
                          .ProjectSummaryLearnMoreAboutCrowdfundingReadMoreLinkW
                      ) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-project-summary-details-list__item _py-1 _mb-2" },
        [
          _c("div", { staticClass: "gfu-hd gfu-hd--h3 _flex _mb-1" }, [
            _c("span", {
              staticClass: "_fa _fa--bullseye-arrow _tc--accent _mr-1",
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.Resources.ProjectHome
                    .ProjectSummaryLearnMoreFundingModeHeader
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-bt gfu-bt--b2" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.Resources.ProjectHome
                    .ProjectSummaryLearnMoreFundingModeText
                ) +
                "\n            "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-project-summary-details-list__item _py-1 _mb-2" },
        [
          _c("div", { staticClass: "gfu-hd gfu-hd--h3 _flex _mb-1" }, [
            _c("span", { staticClass: "_fa _fa--clock _tc--accent _mr-1" }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.Resources.ProjectHome.ProjectSummaryLearnMoreEndDateHeader
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gfu-bt gfu-bt--b2" }, [
            _vm._v(
              "\n                " + _vm._s(_vm.campaignEnd) + "\n            "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gfu-project-summary-details-list__item _py-2" },
        [
          _c("div", { staticClass: "gfu-hd gfu-hd--h3 _flex _mb-1" }, [
            _c("span", { staticClass: "_fa _fa--dice _tc--accent _mr-1" }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.Resources.ProjectHome
                    .ProjectSummaryLearnMorePossibleRisksHeader
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gfu-bt gfu-bt--b2" },
            [
              _c(
                "localize",
                {
                  attrs: {
                    resource:
                      _vm.Resources.ProjectHome
                        .ProjectSummaryLearnMorePossibleRisksText,
                  },
                },
                [
                  _c(
                    "base-link",
                    {
                      staticClass: "gfu-link gfu-link--nofx gfu-link--accent",
                      attrs: { "format-key": "0", url: _vm.risksUrl },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.Resources.ProjectHome
                              .ProjectSummaryLearnMorePossibleRisksLink
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gfu-heading__wrapper _mb-3" }, [
    _c(
      "div",
      {
        staticClass: "gfu-list-info _fc",
        attrs: { "data-qa": "begin-order-item-list-header" },
      },
      [
        _c(
          "div",
          { staticClass: "gfu-list-info__left _mb-1 _flexaicntr" },
          [
            _c(
              "base-link",
              {
                staticClass: "gfu-heading__thumb-wrapper",
                attrs: { url: _vm.projectUrl },
              },
              [
                _c("div", { staticClass: "gfu-heading__thumb" }, [
                  _c("img", {
                    attrs: { src: _vm.thumbUrl, alt: _vm.project.name },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("base-link", { attrs: { url: _vm.projectUrl } }, [
                  _c(
                    "h2",
                    {
                      staticClass: "gfu-heading gfu-heading--text",
                      attrs: { "data-qa": "create-order:ProjectName" },
                    },
                    [
                      _c(
                        "localize",
                        {
                          attrs: {
                            resource:
                              _vm.Resources.Checkout
                                .CheckoutHeaderProjectByCreatorTitle,
                          },
                        },
                        [
                          _c("span", { attrs: { "format-key": "0" } }, [
                            _vm._v(_vm._s(_vm.projectName)),
                          ]),
                          _vm._v(" "),
                          _c("span", { attrs: { "format-key": "1" } }, [
                            _vm._v(_vm._s(_vm.projectCreatorName)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.shouldDisplayPledgeID
                  ? _c("span", { attrs: { "data-qa": "pledge-id" } }, [
                      _vm._v(_vm._s(_vm.pledgeIdentificationCopy)),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isModalVisible
    ? _c(
        "modal-box",
        {
          attrs: {
            "is-visible": "",
            "has-backdrop": "",
            "show-action-close": "",
            width: "fullscreen",
            "is-processing": _vm.isProcessing,
          },
          on: { open: _vm.onModalOpen, "action-close": _vm.onModalClose },
        },
        [
          _c("div", { staticClass: "_flex _jc-c _ai-c _grow-1" }, [
            _c(
              "div",
              { staticClass: "_grid _gap-3 _pa-2 _ji-c" },
              [
                _c("base-image", {
                  staticClass:
                    "gfu-push-notification__image gfu-embed gfu-embed--1x1",
                  attrs: {
                    src: "/content/images/static/push-notification/push-notification-asset.svg",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "_tac" }, [
                  _c("h2", { staticClass: "gfu-hd--h2" }, [
                    _vm._v(
                      _vm._s(_vm.Resources.Layout.PushNotificationModalTitle)
                    ),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.pushNotificationModalText) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "_flexcol _gap-2 _jcc" },
                  [
                    _vm.isPushPermissionStatusDenied
                      ? _c(
                          "base-button",
                          {
                            attrs: { tag: "a", theme: "accent", href: _vm.url },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.Layout.WebPushHowToUnblockLink
                              )
                            ),
                          ]
                        )
                      : _c(
                          "base-button",
                          {
                            attrs: { theme: "accent" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onEnablePush.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.Resources.Layout
                                  .PushNotificationEnableButton
                              )
                            ),
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "_ttl _fw-n _tc--accent",
                        on: { click: _vm.onModalClose },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.Resources.Layout.PushNotificationDenyButton
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
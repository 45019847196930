var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "navigation",
      class: ["gfu-project-navigation", { "is-open": _vm.drawerIsOpen }],
      attrs: {
        id: "project-navigation",
        "data-qa": `project-navigation-sticky:${_vm.isStuck}`,
      },
    },
    [
      _vm.isStuck
        ? _c(
            "top-navigation-bar",
            [
              _c(
                "share-action",
                _vm._b(
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ onShare }) {
                            return [
                              _c("base-icon", {
                                staticClass: "_pa-2",
                                attrs: {
                                  tag: "button",
                                  name: "arrow-up-from-bracket",
                                  size: "lg",
                                },
                                on: { click: onShare },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2074552448
                    ),
                  },
                  "share-action",
                  _vm.sharePayload,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isProjectInPhasePreview
        ? _c("project-navigation-archive", {
            attrs: { "project-url": _vm.projectUrl },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gfu-navbar gfu-project-navigation__navbar gfu-navbar--lighter",
        },
        [
          _c(
            "div",
            {
              class: [
                "gfu-project-navigation-main",
                { "is-stuck": _vm.isStuck },
              ],
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "gfu-project-navigation-main__side _screen--none _screen--flex-xl",
                },
                [
                  _c(
                    "base-link",
                    {
                      staticClass: "gfu-project-navigation-main__logo",
                      attrs: { url: "/", title: "Gamefound" },
                    },
                    [
                      _c("base-image", {
                        attrs: {
                          alt: "Gamefound",
                          src: "/content/images/site-icon.svg",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "h3",
                    { staticClass: "gfu-project-navigation-main__project" },
                    [
                      _c(
                        "base-link",
                        {
                          staticClass: "gfu-link--nofx",
                          attrs: { url: _vm.projectUrl },
                          on: { click: _vm.onProjectNameClicked },
                        },
                        [_vm._v(_vm._s(_vm.projectName))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isStuck && _vm.enableDrawer
                ? _c(
                    "nav",
                    {
                      staticClass:
                        "gfu-navbar-nav gfu-project-navigation-main__header",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "gfu-navbar-item gfu-project-navigation-main__title",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "gfu-navbar-link is-active",
                              attrs: {
                                href: "#projectUrl",
                                "data-qa": `project-navigation-link:${_vm.header}`,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showDrawer(false)
                                },
                              },
                            },
                            [
                              _c("span", {
                                staticClass:
                                  "gfu-navbar-icon _fa _fa--chevron-down",
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "_toe" }, [
                                _vm._v(_vm._s(_vm.header)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "nav",
                    {
                      staticClass:
                        "gfu-project-navigation-main__nav gfu-navbar-nav gfu-navbar-nav--scrollable",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "gfu-navbar-nav__wrapper" },
                        [
                          _vm._l(_vm.items, function (link) {
                            return [
                              link.isActive && _vm.hasSections
                                ? _c(
                                    "div",
                                    {
                                      key: `${link.name}-section`,
                                      ref: "mainNavigationItem",
                                      refInFor: true,
                                      staticClass: "gfu-navbar-item is-active",
                                      attrs: {
                                        "data-qa":
                                          "project-navigation-link:Products",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "gfu-navbar-link",
                                          attrs: { href: link.url },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.showDrawer(true)
                                            },
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "gfu-navbar-icon _fa _fa--chevron-down _screen--not-xl",
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(link.name)),
                                          ]),
                                          _vm._v(" "),
                                          link.badgeText
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "gfu-navbar-badge _ml-1",
                                                  attrs: {
                                                    "data-qa":
                                                      "project-navigation-badge",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(link.badgeText) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c("project-navigation-main-item", {
                                    key: link.name,
                                    ref: "mainNavigationItem",
                                    refInFor: true,
                                    attrs: { link: link },
                                  }),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gfu-project-navigation-main__side" },
                [_c("project-quick-actions")],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "drawer",
        {
          attrs: { "is-open": _vm.drawerIsOpen },
          on: { close: _vm.hideDrawer },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "top-navigation-bar",
                    [
                      _c(
                        "share-action",
                        _vm._b(
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ onShare }) {
                                  return [
                                    _c("base-icon", {
                                      staticClass: "_pa-2",
                                      attrs: {
                                        tag: "button",
                                        name: "arrow-up-from-bracket",
                                        size: "lg",
                                      },
                                      on: { click: onShare },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          },
                          "share-action",
                          _vm.sharePayload,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "gfu-navbar gfu-navbar--lighter" }, [
                    _c(
                      "div",
                      { staticClass: "gfu-project-navigation-main is-stuck" },
                      [
                        _c(
                          "nav",
                          {
                            staticClass:
                              "gfu-navbar-nav gfu-project-navigation-main__header",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "gfu-navbar-item gfu-project-navigation-main__title",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "gfu-navbar-link is-active",
                                    attrs: { href: _vm.projectUrl },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.hideDrawer.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "gfu-navbar-icon _fa _fa--times",
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "_toe" }, [
                                      _vm._v(_vm._s(_vm.header)),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("project-quick-actions", {
                          staticClass: "gfu-project-navigation-main__side",
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "nav",
            { ref: "list", staticClass: "gfu-list" },
            [
              _vm._l(_vm.items, function (link, index) {
                return [
                  link.isRestricted
                    ? _c(
                        "div",
                        { key: link.name, staticClass: "gfu-list-item" },
                        [
                          _c(
                            "tooltip",
                            {
                              attrs: {
                                text: link.restrictionReason,
                                "vertical-position": "down",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "gfu-navbar-link--disabled" },
                                [_c("span", [_vm._v(_vm._s(link.name))])]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : [
                        _c(
                          "base-link",
                          {
                            key: link.name,
                            class: [
                              "gfu-list-item",
                              { "is-active": link.isActive },
                            ],
                            attrs: { url: link.url, title: link.label },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(link.name) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        link.isActive && _vm.hasSections
                          ? _c(
                              "nav",
                              { key: `section-${link.name}${index}` },
                              [
                                _vm._l(_vm.pageSections, function (section) {
                                  return [
                                    _c(
                                      "router-indicator",
                                      {
                                        key: `section-${section.name}`,
                                        staticClass: "gfu-list-subitem",
                                        attrs: {
                                          "active-class": "is-active",
                                          "exact-active-class":
                                            "is-exact-active",
                                          href: "#",
                                          tag: "a",
                                          to: {
                                            name: "page-section",
                                            params: {
                                              section: section.urlName,
                                            },
                                          },
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.onSectionClicked({
                                              section: section.urlName,
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(section.name) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    section.sections && section.sections.length
                                      ? _c(
                                          "nav",
                                          { key: `subsection-${section.name}` },
                                          _vm._l(
                                            section.sections,
                                            function (subSection) {
                                              return _c(
                                                "router-indicator",
                                                {
                                                  key: `${section.urlName}/${subSection.urlName}`,
                                                  staticClass:
                                                    "gfu-list-subitem gfu-list-subitem--indent",
                                                  attrs: {
                                                    "active-class": "is-active",
                                                    "exact-active-class":
                                                      "is-exact-active",
                                                    href: "#",
                                                    tag: "a",
                                                    to: {
                                                      name: "page-section",
                                                      params: {
                                                        section:
                                                          section.urlName,
                                                        subSection:
                                                          subSection.urlName,
                                                      },
                                                    },
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.onSectionClicked(
                                                        {
                                                          section:
                                                            section.urlName,
                                                          subSection:
                                                            subSection.urlName,
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(subSection.name) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("return-to-element"),
      _vm._v(" "),
      _c("project-context-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
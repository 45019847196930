var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLanguageSelectorVisible
    ? _c(
        "div",
        { staticClass: "gfu-grid__cell gfu-1of1 gfu-2of3--m gfu-2of4--l" },
        [
          _c(
            "language-dropdown-options",
            {
              attrs: {
                "display-name": _vm.Resources.Layout.LanguageSelectorModalLabel,
              },
            },
            [
              _c(
                "label",
                { staticClass: "gfu-field__label _tc--light _mb-1" },
                [
                  _c("span", { attrs: { "data-qa": "field-label-text" } }, [
                    _vm._v(
                      _vm._s(_vm.Resources.Layout.LanguageSelectorModalLabel)
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "_flex _jc-c _ai-c _mt-2" },
            [
              _c("base-icon", {
                staticClass: "_pr-2",
                attrs: { size: "lg", name: "lightbulb" },
              }),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "_mt-0 gfu-bt--caption" },
                [
                  _c(
                    "localize",
                    {
                      attrs: {
                        resource:
                          _vm.Resources.Layout
                            .LanguageDropdownReportTranslationIssueParagraph,
                      },
                    },
                    [
                      _c(
                        "base-link",
                        {
                          attrs: {
                            "format-key": "0",
                            url: _vm.contactTranslationUrl,
                            "data-qa": "footer-translation-issue-report-link",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.Resources.Layout
                                  .LanguageDropdownReportTranslationIssueLink
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }